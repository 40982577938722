import React from "react";
import Header from "../components/Header";
import Title from "../components/Title";
import ChooseDateCard from "../components/ChooseDateCard";
import ChooseTimeCard from "../components/ChooseTimeCard";
import Calendar from "../components/Calendar";
import DBtn from "../components/DBtn";
import ShowCookies from "../components/ShowCookies";

const AvailableCalendar = () => {
  return (
    <div>
      <div className="border-b-[1px] border-[#FF914D]">
        <div className="container mx-auto w-full">
          <div className="">
            <Header />
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <ShowCookies />
      </div>

      <div className="mx-auto md:container pt-[40px] font-bold">
        <Title title="Add availability" />
      </div>
      <div className="xl:flex container mx-auto ">
        <div className="container mx-auto">
          <ChooseDateCard />
          <ChooseTimeCard />
        </div>
        <div className="md:flex flex-start">
          <div>
            <Calendar />
          </div>
          <div className="md:pl-[40px] md:pr-[50px]">
            <Calendar />
          </div>
        </div>
      </div>
      <div>
        <div className=" sm:container mx-auto mb-[70px] pt-[32px] flex justify-end">
          <DBtn
            variant="contain"
            size=""
            // onClick={(e) => console.log("button click")}
            startIcon=""
          >
            Save
          </DBtn>
        </div>
      </div>
    </div>
  );
};

export default AvailableCalendar;
