import React, { useEffect, useState } from "react";
import CookieModal from "./CookieModal";
import Cookies from "js-cookie";

const ShowCookies = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasVisitedBefore = Cookies.get("cookieSet");
    if (!hasVisitedBefore) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveCookieSettings = (accepted) => {
    if (accepted) {
      const cookieData = {
        accepted: true,
        timestamp: new Date(),
        expires: "365 Days",
      };
      Cookies.set("cookieSet", JSON.stringify(cookieData), { expires: 365 });
      // console.log("Cookies accepted");
    } else {
      // console.log("Cookies declined");
    }
    setShowModal(false);
  };

  return (
    <div className="fixed bottom-0 z-50   ">
      {showModal && (
        <CookieModal
          onClose={handleCloseModal}
          onSave={handleSaveCookieSettings}
        />
      )}
    </div>
  );
};

export default ShowCookies;
