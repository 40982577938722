import React from "react";
import DBtn from "../components/DBtn";
import IdVarification from "../components/IdVarification";
import ShowCookies from "../components/ShowCookies";

const Verification = () => {
  return (
    <div className="container mx-auto p-4 flex justify-center items-center h-screen ">
      <ShowCookies />
      <div className="border-[1px] border-[#FF914D] w-[520px] h-fit text-center py-8 rounded-md">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            className="mx-auto"
          >
            <path
              d="M2.6893 26.4572C3.32658 27.0126 3.85043 27.7097 3.79941 28.184C3.76943 28.4673 3.75287 28.7553 3.75287 29.0479C3.75287 33.3587 6.99459 36.8529 10.993 36.8529C11.4869 36.8529 11.9687 36.7994 12.4349 36.6978C13.22 36.5271 14.147 36.8575 14.7024 37.5035C16.0226 39.0377 17.9051 40 20 40C22.2127 40 24.1935 38.9291 25.5213 37.2422C26.049 36.572 26.9422 36.2933 27.7363 36.5429C28.3773 36.7442 29.0558 36.8529 29.7576 36.8529C33.7559 36.8529 36.9977 33.358 36.9977 29.0479C36.9977 28.5657 36.9557 28.0943 36.8761 27.6373C36.7417 26.8663 37.0998 25.9056 37.698 25.3037C39.1128 23.8812 40 21.8576 40 19.6066C40 17.1558 38.9507 14.9725 37.3106 13.5429C36.6734 12.9874 36.1495 12.2903 36.2005 11.8159C36.2306 11.5327 36.2471 11.2447 36.2471 10.952C36.2471 6.6412 33.0052 3.14712 29.0069 3.14712C28.5131 3.14712 28.0312 3.2006 27.5651 3.30213C26.78 3.47286 25.853 3.14243 25.2976 2.49647C23.9773 0.962237 22.0948 0 20 0C17.9052 0 16.0227 0.962237 14.7024 2.49647C14.147 3.14161 13.22 3.47286 12.4349 3.30213C11.9688 3.2006 11.4869 3.14712 10.993 3.14712C6.99467 3.14712 3.75287 6.64202 3.75287 10.952C3.75287 11.3674 3.78364 11.7749 3.8429 12.1723C3.94195 12.8411 3.49384 13.6656 2.84236 14.203C1.11608 15.6279 0 17.8678 0 20.3934C0 22.8442 1.04928 25.0276 2.6893 26.4572ZM10.3198 20.6759C10.4804 20.3776 10.8902 20.3737 11.2655 20.7153L15.0425 23.506C15.2473 23.6366 15.4433 23.7034 15.6316 23.7034C16.0212 23.7034 16.3297 23.4092 16.6276 23.1258L28.7142 10.4437C29.47 9.58222 30.2048 9.27853 30.6845 9.60896C30.9059 9.76158 31.0492 9.9913 31.0876 10.2565C31.1334 10.5712 31.0283 10.9103 30.7918 11.2108L16.2591 29.7159C16.0482 29.9841 15.7795 30.1321 15.5033 30.1321C15.1753 30.1321 14.8781 29.9251 14.6867 29.5648L10.4211 21.5042C10.2447 21.2029 10.2056 20.89 10.3198 20.6759Z"
              fill="#0F9E46"
            />
          </svg>
        </div>
        <div className="pt-[32px]">
          <span className="text-2xl font-bold font-Comfortaa">
            Become a verified Host now!
          </span>
          <div className="font-Comfortaa font-bold text-xl mt-2">
            <span>ID verification </span>
          </div>
          <div className="pt-[40px] font-Glacial text-lg text-[#474747]">
            <span>
              Upload Frontside <sup className="text-[#FF914D]">*</sup>
            </span>
          </div>
          <div className="border-[1px] border-dashed border-[#FF914D] w-[396px] h-fit mx-auto mt-[24px] ">
            <IdVarification />
          </div>
          <div className="pt-[40px] font-Glacial text-lg text-[#474747]">
            <span>
              Upload Backside <sup className="text-[#FF914D]">*</sup>
            </span>
          </div>
          <div className="border-[1px] border-dashed border-[#FF914D] w-[396px] h-fit mx-auto mt-[24px]">
            <IdVarification />
          </div>
        </div>
        <div className="py-8">
          <DBtn
            variant="contain"
            size="xlarge"
            // onClick={(e) => console.log("button click")}
            startIcon=""
          >
            Verify
          </DBtn>
        </div>
      </div>
    </div>
  );
};

export default Verification;
