import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { addBankDetails } from "../services/profileService";

const AddBankDetailsModal = ({ setIsModalOpen, setLocationModal }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  //   const [locationModal, setLocationModal] = useState(false);
  const [bankLoading, setBankLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [bankDetails, setBankDetails] = useState({
    accountHolderName: "",
    bankName: "",
    accountNo: "",
    country: "",
    IBAN: "",
    swiftCode: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBankDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!bankDetails.accountHolderName) {
      newErrors.accountHolderName =
        lang === "en" || lang === "en-US"
          ? "Account Holder Name is required."
          : "Se requiere el nombre del titular de la cuenta.";
    }

    if (!bankDetails.bankName) {
      newErrors.bankName =
        lang === "en" || lang === "en-US"
          ? "Bank Name is required."
          : "Se requiere el nombre del banco.";
    }

    if (!bankDetails.accountNo) {
      newErrors.accountNo =
        lang === "en" || lang === "en-US"
          ? "Account Number is required."
          : "Se requiere el número de cuenta.";
    }

    if (!bankDetails.country) {
      newErrors.country =
        lang === "en" || lang === "en-US"
          ? "Country is required."
          : "Se requiere el país.";
    }

    if (!bankDetails.IBAN) {
      newErrors.IBAN =
        lang === "en" || lang === "en-US"
          ? "IBAN is required."
          : "Se requiere el IBAN.";
    }

    if (!bankDetails.swiftCode) {
      newErrors.swiftCode =
        lang === "en" || lang === "en-US"
          ? "SWIFT Code is required."
          : "Se requiere el código SWIFT.";
    }

    return newErrors;
  };

  const handleBankDetails = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setBankLoading(true);
    try {
      const payload = { ...bankDetails };
      const resp = await addBankDetails(payload);
      if (resp?.success) {
        // toast.success(
        //   lang === "en" || lang === "en-US"
        //     ? resp?.message?.en
        //     : resp?.message?.es
        // );
        setLocationModal(true);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error("err:", error);
    } finally {
      setBankLoading(false);
    }
  };

  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg w-[90%] min-w-[310px] max-w-[800px] relative">
          <div className="p-6 text-center mt-4">
            <div className="flex justify-center">
              <img src="../images/Loginform/logo.svg" alt="" />
            </div>

            <p className="flex justify-center font-Comfortaa-Bold text-2xl text-[#111] mt-2 mb-1">
              {t("homePage.welcome")}
            </p>

            <span className="flex justify-center text-[16px] font-Glacial text-[#757575]">
              {t("profilePage.addBankInfo")}
            </span>

            <div className="my-10">
              <div
                className={`grid grid-cols-1 md:grid-cols-2 gap-4 ${
                  errors.accountHolderName || errors.accountNo
                    ? "mt-2"
                    : "mt-10"
                }
  `}
              >
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/accName.svg" alt="" />
                  </span>
                  <div>
                    <input
                      type="text"
                      name="accountHolderName"
                      value={bankDetails.accountHolderName}
                      onChange={handleChange}
                      placeholder={t("profilePage.enterAccHolderName")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />

                    {errors.accountHolderName && (
                      <p className="text-red-400 text-left text-base mt-[2px]">
                        {errors.accountHolderName}
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/bankAcc.svg" alt="" />
                  </span>
                  <div>
                    <input
                      type="text"
                      name="accountNo"
                      value={bankDetails.accountNo}
                      onChange={handleChange}
                      placeholder={t("profilePage.enterAccHolderNumber")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />{" "}
                    {errors.accountNo && (
                      <p className="text-red-400 text-left text-base mt-[2px]">
                        {errors.accountNo}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`grid grid-cols-1 md:grid-cols-2 gap-4 ${
                  errors.country || errors.swiftCode ? "mt-2" : "mt-10"
                }
  `}
              >
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/country.svg" alt="" />
                  </span>
                  <div>
                    <input
                      type="text"
                      name="country"
                      value={bankDetails.country}
                      onChange={handleChange}
                      placeholder={t("profilePage.enterCountry")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />{" "}
                    {errors.country && (
                      <p className="text-red-400 text-left text-base mt-[2px]">
                        {errors.country}
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/SWIFT.svg" alt="" />
                  </span>
                  <div>
                    <input
                      type="text"
                      name="swiftCode"
                      value={bankDetails.swiftCode}
                      onChange={handleChange}
                      placeholder={t("profilePage.enterSWIFT")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />{" "}
                    {errors.swiftCode && (
                      <p className="text-red-400 text-left text-base mt-[2px]">
                        {errors.swiftCode}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`grid grid-cols-1 md:grid-cols-2 gap-4 ${
                  errors.bankName || errors.iban ? "mt-2" : "mt-10"
                }
  `}
              >
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/bankAcc.svg" alt="" />
                  </span>
                  <div>
                    <input
                      type="text"
                      name="bankName"
                      value={bankDetails.bankName}
                      onChange={handleChange}
                      placeholder={t("profilePage.enterBankName")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />
                    {errors.bankName && (
                      <p className="text-red-400 text-left text-base mt-[2px]">
                        {errors.bankName}
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/IBAN.svg" alt="" />
                  </span>
                  <div>
                    <input
                      type="text"
                      name="IBAN"
                      value={bankDetails.IBAN}
                      onChange={handleChange}
                      placeholder={t("profilePage.enterIBAN")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />
                    {errors.IBAN && (
                      <p className="text-red-400 text-left text-base mt-[2px]">
                        {errors.IBAN}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="bottom-6 right-6 flex gap-4 justify-end">
              <button
                onClick={handleBankDetails}
                disabled={bankLoading}
                className={`bg-primary font-Comfortaa-Medium text-white py-2 px-4 rounded-full font-medium text-sm hover:bg-primary-dark focus:outline-none ${
                  bankLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {bankLoading
                  ? lang === "en" || lang === "en-US"
                    ? "Saving..."
                    : "Ahorro..."
                  : lang === "en" || lang === "en-US"
                  ? "Save and Next"
                  : "Guardar y Siguiente"}
              </button>
              <button
                className="bg-gray-300 font-Comfortaa-Medium text-gray-700 py-2 px-4 rounded-full font-medium text-sm hover:bg-gray-400 focusbaseutline-none"
                onClick={() => {
                  setIsModalOpen(false);
                  setLocationModal(true);
                }}
              >
                {lang === "en" || lang === "en-US" ? "Skip" : "Saltar"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBankDetailsModal;
