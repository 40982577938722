import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { contactUs } from "../services/authService";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { HiOutlineUser } from "react-icons/hi";
import { BiMessageDetail } from "react-icons/bi";
import ShowCookies from "../components/ShowCookies";

const ContactPage = () => {
  const { t } = useTranslation();
  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  const [errors, setErrors] = useState({});
  const [errorsEs, setErrorsEs] = useState({});
  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const lang = localStorage.getItem("i18nextLng");
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    const newErrorsEs = {};

    // Validate email
    if (!contact.email) {
      newErrors.email = "Email is required";
      newErrorsEs.email = "Correo electronico es requerido.";
      isValid = false;
    }

    if (!isEmail(contact.email) && contact.email) {
      newErrors.emailvalid = "Enter valid email address";
      newErrorsEs.emailvalid = "Introduzca un correo electronico valido.";
      isValid = false;
    }

    // Validate name
    if (!contact.name) {
      newErrors.name = "Name is required";
      newErrorsEs.name = "Se requiere el nombre";
      isValid = false;
    }

    // Validate message
    if (!contact.message) {
      newErrors.message = "Message is required";
      newErrorsEs.message = "Se requiere el mensaje";
      isValid = false;
    }
    setErrorsEs(newErrorsEs);
    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    setContact({
      ...contact,
      [name]: value,
    });
  };

  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);

      const payload = {
        email: contact?.email,
        message: contact?.message,
        name: contact?.name,
      };

      try {
        const resp = await contactUs(payload);
        // console.log(resp);

        if (resp?.success) {
          {
            lang === "en" || lang === "en-US"
              ? toast.success(resp?.message?.en)
              : toast.success(resp?.message?.es);
          }

          setContact({
            ...contact,
            name: "",
            email: "",
            message: "",
          });
        } else {
          toast.error(
            lang === "en" || lang === "en-US"
              ? resp?.message?.en
              : resp?.message?.es
          );
        }
      } catch (err) {
        console.error("err", err);
        toast.error(err);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <div className="container mx-auto w-full">
        <Header />
      </div>
      <div className="container mx-auto">
        <ShowCookies />
      </div>

      <div className="banner-container">
        <img
          src="https://cdn.pixabay.com/photo/2023/06/20/17/30/youtube-banner-8077450_1280.jpg"
          alt=""
          className="w-full h-[30vh] object-cover"
        />
      </div>

      <div className="container mx-auto ">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 text-center">
          <h2 className="text-[40px] font-bold font-Comfortaa">
            {t("contactPage.getInTouch")}
          </h2>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8 pb-16 grid md:grid-cols-2 lg:grid-cols-2 gap-y-8 md:gap-x-8 md:gap-y-8 lg:gap-x-24 lg:gap-y-16">
          <div>
            <p className="text-[30px] font-bold font-Comfortaa">
              {t("contactPage.supportDetails")}
            </p>
            {lang === "en" || lang === "en-US" ? (
              <p className="pt-6 pb-6   m-auto text-[20px] font-Glacial Indifference font-normal">
                For Any Query Regarding To service Need Assistance? Drop us a
                message.
              </p>
            ) : (
              <p className="pt-6 pb-6   m-auto text-[20px] font-Glacial Indifference font-normal">
                Para cualquier consulta sobre el servicio ¿Necesita ayuda?
                Déjanos un mensaje.
              </p>
            )}
            <span className="border-b-4 border-[#FF914D] h-[2px] w-[80px]"></span>

            <div className="relative flex items-center py-2 ">
              <div className="absolute text-2xl text-primary p-[12px] only: w-[50px] h-[50px] border-r bg-transparent ">
                <img src="../images/Loginform/maile.svg" alt="" />
              </div>
              <a
                href="mailto:contact@mydish4u.com"
                className="w-full cursor-pointer  mx-auto h-[50px] pt-[7px] text-primary outline-none rounded-lg ps-[80px] pe-20 font-Glacial sm:text-[20px] text-[16px] border border-gray-300 hover:border-primary hover:ring-2 hover:ring-primary placeholder:text-primary"
              >
                contact@mydish4u.com
              </a>
            </div>
            <div className="relative flex items-center py-2">
              <div className="absolute text-2xl text-primary p-[12px] only: w-[50px] h-[50px] border-r bg-transparent ">
                <img src="../images/Loginform/Vector.svg" alt="" />
              </div>
              <a
                href="tel:+911234567890"
                className="w-full cursor-pointer mx-auto h-[50px] pt-[10px] text-primary outline-none rounded-lg ps-[80px] pe-20 font-Glacial sm:text-[20px] text-[16px] border border-gray-300 hover:border-primary hover:ring-2 hover:ring-primary placeholder:text-primary"
              >
                (+34) 635 66 13 82
              </a>
            </div>
            <div className="relative flex items-center py-2">
              <div className="absolute text-2xl text-primary p-[12px] only: w-[50px] h-[80px]  border-r bg-transparent ">
                <img
                  src="../images/Loginform/location.svg"
                  alt=""
                  className="w-12 pt-4"
                />
              </div>
              <a
                // href="tel:+911234567890"
                className="w-full cursor-pointer  mx-auto h-[80px] pt-[10px] text-primary outline-none rounded-lg ps-[80px]  font-Glacial sm:text-[20px] text-[16px] border border-gray-300 hover:border-primary hover:ring-2 hover:ring-primary placeholder:text-primary"
              >
                Silvestre Bello 7 2a, Santa Lucía de Tirajana 35110 Las Palmas
              </a>
            </div>
          </div>
          <div>
            <form className="font-Glacial">
              <div className="mb-5 relative">
                <div className="absolute text-2xl text-primary p-[12px] only: w-[50px] h-[50px] border-r bg-transparent ">
                  <HiOutlineUser />
                </div>
                <input
                  type="text"
                  placeholder={t("contactPage.fullName")}
                  className="w-full px-4 py-3 border-2 ps-[60px] placeholder:text-gray-500  transition delay-150 text-[18px]  rounded-md focus:ring-1 border-gray-300 focus:border-2 focus:border-primary focus:ring-primary "
                  name="name"
                  value={contact?.name}
                  onChange={handleInputChange}
                />
                {lang === "en" || lang === "en-US" ? (
                  <>
                    {errors?.name && contact?.name === "" && (
                      <div className="text-red-300 ml-4">{errors.name}</div>
                    )}
                  </>
                ) : (
                  <>
                    {errorsEs?.name && contact?.name === "" && (
                      <div className="text-red-300 ml-4">{errorsEs.name}</div>
                    )}
                  </>
                )}
              </div>
              <div className="mb-5 relative">
                <div className="absolute text-2xl text-primary p-[12px] only: w-[50px] h-[50px] border-r bg-transparent ">
                  <img src="../images/Loginform/maile.svg" alt="" />
                </div>
                <input
                  type="email"
                  placeholder={t("contactPage.email")}
                  className="w-full px-4 py-3 border-2 ps-[60px] placeholder:text-gray-500 transition delay-150  text-[18px]  rounded-md focus:ring-1 border-gray-300 focus:border-2 focus:border-primary focus:ring-primary"
                  name="email"
                  value={contact?.email}
                  onChange={handleInputChange}
                />
                {lang === "en" || lang === "en-US" ? (
                  <>
                    {errors.email && contact?.email === "" && (
                      <div className="text-red-300 ml-4">{errors.email}</div>
                    )}
                    {errors.emailvalid && (
                      <div className="text-red-300 ml-4 font-Glacial">
                        {errors.emailvalid}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {errorsEs.email && contact?.email === "" && (
                      <div className="text-red-300 ml-4">{errorsEs.email}</div>
                    )}
                    {errorsEs.emailvalid && (
                      <div className="text-red-300 ml-20 font-Glacial">
                        {errorsEs.emailvalid}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="mb-5 relative">
                <div className="absolute text-2xl text-primary p-[12px] only: w-[50px] h-[50px] border-r border-b rounded-lg bg-transparent ">
                  <BiMessageDetail />
                </div>
                <textarea
                  placeholder={t("contactPage.message")}
                  className="w-full px-4 py-3 ps-[60px] border-2 placeholder:text-gray-500 transition delay-150  text-[18px]  rounded-md focus:ring-1 border-gray-300 focus:border-2 focus:border-primary focus:ring-primary h-36"
                  name="message"
                  value={contact?.message}
                  onChange={handleInputChange}
                ></textarea>
                {lang === "en" || lang === "en-US" ? (
                  <>
                    {" "}
                    {errors?.message && contact?.message === "" && (
                      <div className="text-red-300 ml-4">{errors.message}</div>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    {errorsEs?.message && contact?.message === "" && (
                      <div className="text-red-300 ml-4">
                        {errorsEs.message}
                      </div>
                    )}
                  </>
                )}
              </div>
              <button
                type="submit"
                onClick={handleContactFormSubmit}
                className="w-full py-4 font-semibold text-white  bg-primary rounded-lg font-Comfortaa-Medium text-[16px] "
              >
                {loading ? (
                  <> {t("contactPage.loadSubmit")} ... </>
                ) : (
                  <> {t("contactPage.buttomSubmit")}</>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactPage;
