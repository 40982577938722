import React, { useEffect, useState } from "react";
import Select from "react-select";
import Header from "../components/Header";
import Title from "../components/Title";
import Footer from "../components/Footer";
import DBtn from "../components/DBtn";
import Dish from "../svg/Dish";
import Dropdown from "../components/Dropdown";
import FoodCategory from "../svg/FoodCategory";
import FourSqure from "../svg/FourSqure";
import DishPrice from "../svg/DishPrice";
import GuestCapacity from "../svg/GuestCapacity";
import Cancelation from "../svg/Cancelation";
import { AiOutlineDown } from "react-icons/ai";
import { IoCloseOutline, IoCloudUploadOutline } from "react-icons/io5";
import {
  editHostListing,
  getCancellation,
  getFoodCategory,
  getFoodTime,
  uploadSingleImage,
} from "../services/commonService";
import { Link, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { getAvailability, profileCompletion } from "../services/profileService";
import Modal from "../components/Modal";
import { FaArrowRightLong } from "react-icons/fa6";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";
const DishForm = () => {
  const navigate = useNavigate();
  const root = JSON.parse(localStorage.getItem("root"));
  const lang = localStorage.getItem("i18nextLng");
  const [loading, setLoading] = useState(false);
  const authData = root.auth;
  const userid = JSON.parse(authData).user;
  const hostid = userid.id;
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [errorsEs, setErrorsEs] = useState({});
  const [flag, setFlag] = useState(false);
  const [configs, setConfigs] = useState({
    categoryEn: [],
    timeEn: [],
    cancelEn: [],
    category: [],
    time: [],
    cancel: [],
  });
  const [dishData, setDishData] = useState({
    dishTitle: "",
    enDishTitle: "",
    enDishDescription: "",
    dishPrice: "",
    mealTiming: [],
    foodClass: "",
    enFoodClass: "",
    dishDescription: "",
    maxDishCapacity: "",
    maxDiningCapacity: "",
    placeAccessibility: [],
    cancellationPolicy: "",
    mainImage: "",
    foodType: "",
    otherImages: [],
    placeImages: [],
    hostId: "",
  });
  // console.log("🚀 ~ DishForm ~ dishData:", dishData);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [inputText, setInputText] = useState("");
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    setFlag(true);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    const newErrorsEs = {};
    if (!dishData.dishTitle) {
      newErrors.dishTitle = "Dish title is required.";
      newErrorsEs.dishTitle = "Se requiere título del plato.";
      isValid = false;
    }
    if (!dishData.dishPrice) {
      newErrors.dishPrice = "Dish Price is required.";
      newErrorsEs.dishPrice = "El precio del plato es obligatorio.";
      isValid = false;
    }
    if (!dishData.maxDiningCapacity) {
      newErrors.maxDiningCapacity = "Guest Capacity is required.";
      newErrorsEs.maxDiningCapacity = "Se requiere capacidad para huéspedes.";
      isValid = false;
    }
    if (!dishData.mainImage) {
      newErrors.mainImage = "Image is required.";
      newErrorsEs.mainImage = "Se requiere imagen.";
      isValid = false;
    }
    if (!dishData.otherImages || dishData.otherImages.length === 0) {
      newErrors.otherImages = "Food images are required.";
      newErrorsEs.otherImages = "Se requieren imágenes de alimentos.";
      isValid = false;
    }
    if (!dishData.placeImages || dishData.placeImages.length === 0) {
      newErrors.placeImages = "Place images are required.";
      newErrorsEs.placeImages = "Se requieren imágenes del lugar.";
      isValid = false;
    }

    if (!dishData.foodClass) {
      newErrors.foodClass = "Food category is required.";
      newErrorsEs.foodClass = "Se requiere categoría de alimento.";
      isValid = false;
    }
    if (!dishData.foodType) {
      newErrors.foodType = "Food type is required.";
      newErrorsEs.foodType = "Se requiere tipo de comida.";
      isValid = false;
    }
    if (!dishData.mealTiming || dishData.mealTiming.length === 0) {
      newErrors.mealTiming = "Timings are required.";
      newErrorsEs.mealTiming = "Se requieren tiempos.";
      isValid = false;
    }
    if (!dishData.dishDescription) {
      newErrors.dishDescription = "Dish description is required.";
      newErrorsEs.dishDescription = "Se requiere descripción del plato.";
      isValid = false;
    }
    if (!dishData.cancellationPolicy) {
      newErrors.cancellationPolicy = "Cancelation policy is required.";
      newErrorsEs.cancellationPolicy = "Se requiere política de cancelación.";
      isValid = false;
    }
    if (
      !dishData.placeAccessibility ||
      dishData.placeAccessibility.length === 0
    ) {
      newErrors.placeAccessibility = "Place accesibility are required.";
      newErrorsEs.placeAccessibility = "Se requiere accesibilidad al lugar.";
      isValid = false;
    }

    // console.log("errors: ", newErrors);
    setErrorsEs(newErrorsEs);
    setErrors(newErrors);
    return isValid;
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleRemoveOtherImage = (index) => {
    const updatedImages = [...dishData.otherImages];
    updatedImages.splice(index, 1);
    setDishData({ ...dishData, otherImages: updatedImages });
  };
  const handleRemovePlaceImage = (index) => {
    const updatedImages = [...dishData.placeImages];
    updatedImages.splice(index, 1);
    setDishData({ ...dishData, placeImages: updatedImages });
  };
  const checkAvailabilityOfHost = async () => {
    try {
      let resp = await getAvailability();
      if (resp?.success) {
        setIsAvailable(false);
      }
    } catch (error) {
      setIsAvailable(true);
      console.error(error);
    }
  };

  const getProfileCompletion = async () => {
    try {
      let profileComplete = await profileCompletion();
      let status = profileComplete?.err;

      if (status === "PROFILE_COMPLETE") {
        // checkAvailabilityOfHost();
        setIsModalOpen(false);
      } else {
        let missingFields = profileComplete?.data?.missingFields;

        if (Array.isArray(missingFields) && missingFields.length > 0) {
          const missingFieldsString = missingFields.join(", ");

          toast.error(
            lang === "en" || lang === "en-US"
              ? `Please enter ${missingFieldsString}`
              : `Por favor escribe ${missingFieldsString}`
          );
          // console.log("missingFieldsString", missingFieldsString);
        }
        setIsModalOpen(true);
        // navigate("/add-dish");
      }
    } catch (err) {
      console.error("Profile completion error", err);
    }
  };

  useEffect(() => {
    if (flag) {
      getProfileCompletion();
      setFlag(false);
    }
  }, [flag]);

  const getFoodData = async () => {
    try {
      let foodCategory;
      let foodTime;
      let cancellation;

      let foodData;
      let timeData;
      let foodCancellation;
      let foodDataEn;
      let timeDataEn;
      let foodCancellationEn;

      foodCategory = await getFoodCategory();

      foodDataEn = foodCategory?.data?.map((item) => {
        return {
          value: item?._id,
          label: item?.translations?.en?.foodType,
        };
      });
      // console.log("foodDataen", foodDataEn);
      foodData = foodCategory?.data?.map((item) => {
        return {
          value: item?._id,
          label: item?.foodType,
        };
      });

      foodTime = await getFoodTime();
      timeDataEn = foodTime?.data?.map((item) => {
        return {
          value: item?._id,
          label: item?.translations?.en?.timingName,
        };
      });
      // console.log("timeDataen", timeDataEn);
      timeData = foodTime?.data?.map((item) => {
        return {
          value: item?._id,
          label: item?.timingName,
        };
      });

      cancellation = await getCancellation();

      foodCancellationEn = cancellation?.data?.map((item) => {
        return {
          value: item?._id,
          label: item?.translations?.en?.policyDetails,
        };
      });
      // console.log("foodCancellationEn", foodCancellationEn);

      foodCancellation = cancellation?.data?.map((item) => {
        return {
          value: item?._id,
          label: item?.policyDetails,
        };
      });

      setConfigs({
        ...configs,
        categoryEn: foodDataEn,
        timeEn: timeDataEn,
        cancelEn: foodCancellationEn,
        category: foodData,
        time: timeData,
        cancel: foodCancellation,
      });
    } catch (err) {
      console.error("err", err);
    } finally {
    }
  };

  useEffect(() => {
    getFoodData();
  }, []);

  const handleInputChange1 = async (e) => {
    const { name, value, files } = e.target;
    if (files?.length > 0) {
      const image = files[files.length - 1];

      const imageData = new FormData();
      imageData.append("image", image);
      try {
        const resp = await uploadSingleImage(imageData);

        if (resp?.success) {
          setDishData({
            ...dishData,
            [name]: resp?.data,
          });
        } else {
          setDishData({
            ...dishData,
            [name]: "",
          });
        }
      } catch (err) {
        console.error("err", err);
      } finally {
      }
    } else if (value) {
      setDishData({
        ...dishData,
        [name]: value,
      });
    }
  };

  const handleInputChange = async (e) => {
    const { name, value, files } = e.target;

    if (files?.length > 0) {
      const newImages = Array.from(files);

      const imagePreviews = newImages.map((image) =>
        URL.createObjectURL(image)
      );

      setDishData((prevData) => ({
        ...prevData,
        [`${name}Previews`]: [
          ...(prevData[`${name}Previews`] || []),
          ...imagePreviews,
        ],
      }));

      const uploadedImages = await Promise.all(
        newImages.map(async (image) => {
          const imageData = new FormData();
          imageData.append("image", image);

          try {
            const resp = await uploadSingleImage(imageData);

            if (resp?.success) {
              return resp.data;
            } else {
              return "";
            }
          } catch (err) {
            console.error("err", err);
            return "";
          }
        })
      );

      setDishData((prevData) => ({
        ...prevData,
        [name]: [...(prevData[name] || []), ...uploadedImages],
      }));
    } else if (value) {
      setDishData({
        ...dishData,
        [name]: value,
      });
    }
  };

  const resetAllInputs = () => {
    setDishData({
      dishTitle: "",
      enDishTitle: "",
      dishPrice: "",
      mealTiming: [],
      foodClass: "",
      dishDescription: "",
      maxDishCapacity: "",
      maxDiningCapacity: "",
      placeAccessibility: [],
      cancellationPolicy: "",
      mainImage: "",
      foodType: "",
      otherImages: [],
      placeImages: [],
      hostId: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      dishData.maxDishCapacity = dishData.maxDiningCapacity;

      let payload = {
        mealTiming: dishData?.mealTiming,
        foodClass: dishData?.foodClass,
        dishTitle: dishData?.dishTitle,
        mealTiming: dishData?.mealTiming,
        // enDishTitle:dishData?.enDishTitle,
        dishDescription: dishData?.dishDescription,
        maxDishCapacity: dishData?.maxDishCapacity,
        maxDiningCapacity: dishData?.maxDiningCapacity,
        dishPrice: dishData?.dishPrice,
        placeAccessibility: dishData?.placeAccessibility,
        cancellationPolicy: dishData?.cancellationPolicy,
        mainImage: dishData?.mainImage,
        foodType: dishData?.foodType,
        otherImages: dishData?.otherImages,
        placeImages: dishData?.placeImages,
        hostId: hostid,
        translations: {
          en: {
            foodClass: dishData?.enFoodClass,
            dishTitle: dishData?.enDishTitle,
            dishDescription: dishData?.enDishDescription,
            placeAccessibility: dishData?.placeAccessibility,
          },
        },
      };
      // console.log("payload", payload);

      try {
        setLoading(true);
        const resp = await editHostListing(payload);
        // console.log("🚀 ~ handleSubmit ~ resp:", resp);

        if (resp?.success) {
          resetAllInputs();
          // console.log("done data is send successfully", resp);
          toast.success(
            lang === "en" || lang === "en-US"
              ? resp?.message?.en
              : resp?.message?.es
          );
          navigate("/");
          resetAllInputs();
        } else {
          // console.log("Data not sent");
        }
      } catch (err) {
        console.error("err", err);
      } finally {
        setLoading(false);
      }
    } else {
      // console.log("form is not validated");
    }
  };

  const CustomDropdownIndicator = (props) => (
    <div {...props} style={{ marginRight: "24px", marginTop: "10px" }}>
      <AiOutlineDown color="#FF914D" size={16} />
    </div>
  );

  const handleInputChangeAcce = (e) => {
    setInputText(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      addLabel();
    }
  };

  const addLabel = () => {
    if (inputText.trim() !== "") {
      const updatedLabels = [...labels, inputText.trim()];
      setLabels(updatedLabels);
      setInputText("");
      updateDishData(updatedLabels);
    }
  };

  const removeLabel = (index) => {
    const updatedLabels = [...labels];
    updatedLabels.splice(index, 1);
    setLabels(updatedLabels);
    updateDishData(updatedLabels);
  };

  const updateDishData = (updatedLabels) => {
    setDishData((prevData) => ({
      ...prevData,
      placeAccessibility: updatedLabels,
    }));
  };

  const handleImageDelete = () => {
    setDishData((prevState) => ({
      ...prevState,
      mainImage: null,
    }));
  };

  return (
    <div>
      <div className="border-b-[1px] border-[#FF914D]">
        <div className="container mx-auto w-full">
          <div className="">
            <Header />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal
          title={t("addNewDish.modalTitle")}
          width={600}
          onClose={() => {
            setIsModalOpen(false);
            navigate("/");
          }}
        >
          <div className="text-xl font-Comfortaa mx-10 my-8   ">
            <>
              <p>{t("addNewDish.modalText")}</p>
              <br />
              <Link
                to="/profile"
                className="text-primary  flex gap-2 items-center "
              >
                {t("addNewDish.modalLink")}...
                <FaArrowRightLong />
              </Link>
            </>
          </div>
        </Modal>
      )}
      {isAvailable && (
        <Modal
          title={t("addNewDish.alert")}
          width={600}
          onClose={() => {
            setIsAvailable(false);
            navigate("/");
          }}
        >
          <div className="text-xl font-Comfortaa mx-10 my-4   ">
            <>
              <p>{t("addNewDish.alertText")}</p>
              <br />
              <Link
                to="/availabel-list"
                className="text-primary  flex gap-2 items-center "
              >
                {t("addNewDish.alertLink")}...
                <FaArrowRightLong />
              </Link>
            </>
          </div>
        </Modal>
      )}
      <div className="m-auto md:container pt-[40px] font-bold">
        <Title title={t("addNewDish.addDish")} />
      </div>

      <div className="pt-4 container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* dish title */}
        <div className=" border-[1px] border-gray-300 rounded-2xl px-[16px]">
          <p className="text-lg font-Glacial text-[#111] pt-[16px]">
            {t("addNewDish.dishTitle")}
            <span className="text-red-500">*</span>
          </p>
          <p className="flex">
            <span
              className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
            ></span>
          </p>
          {/* spanish */}
          <div className="relative flex pt-[21px] font-Glacial">
            <span className="absolute inline-flex items-center h-[50px]  px-3 border-r text-sm text-gray-900 bg-transparent   rounded-e-0 border-gray-300 rounded-s-xl ">
              <Dish />
            </span>

            <input
              type="text"
              name="dishTitle"
              onChange={handleInputChange1}
              placeholder={t("addNewDish.enterDishName")}
              className=" rounded-xl bg-white border text-gray-900 pl-16  block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-2 focus:ring-primary focus:border-none "
            />
          </div>
          {lang === "en" || lang === "en-US" ? (
            <>
              {errors.dishTitle && dishData?.dishTitle === "" && (
                <div className="text-red-300 font-Glacial ml-6 text-lg">
                  {errors.dishTitle}
                </div>
              )}
            </>
          ) : (
            <>
              {errorsEs.dishTitle && dishData?.dishTitle === "" && (
                <div className="text-red-300 font-Glacial ml-6 text-lg">
                  {errorsEs.dishTitle}
                </div>
              )}
            </>
          )}

          {/* english  */}
          <>
            <div className="pt-[10px] font-Glacial pb-2">
              <span className="text-lg">{t("english")}</span>
            </div>
            <div className="relative flex pt-[0px] font-Glacial pb-3">
              <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                <Dish />
              </span>
              <input
                type="text"
                name="enDishTitle"
                onChange={handleInputChange1}
                placeholder={t("addNewDish.enterDishName")}
                className="rounded-xl bg-white border pl-16 text-gray-900  block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
              />
            </div>
          </>
        </div>
        {/* dish price */}
        <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] pb-[24px]">
          <p className="text-lg font-Glacial text-[#111] pt-[16px]">
            {t("addNewDish.dishPrice")}
            <span className="text-red-500">*</span>
          </p>
          <p className="flex">
            <span
              className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
            ></span>
          </p>
          <div className="pt-[24px]">
            <div className="relative flex  font-Glacial">
              <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0 border-gray-300 rounded-s-xl ">
                <DishPrice />
              </span>

              <input
                name="dishPrice"
                type="number"
                placeholder={t("addNewDish.enterDishPrice")}
                onChange={handleInputChange}
                className=" rounded-xl pl-16 bg-white border text-gray-900  block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
              />
            </div>

            {lang === "en" || lang === "en-US" ? (
              <>
                {errors.dishPrice && dishData?.dishPrice === "" && (
                  <div className="text-red-300 font-Glacial ml-6 text-lg">
                    {errors.dishPrice}
                  </div>
                )}
              </>
            ) : (
              <>
                {errorsEs.dishPrice && dishData?.dishPrice === "" && (
                  <div className="text-red-300 font-Glacial ml-6 text-lg">
                    {errorsEs.dishPrice}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* guestcapacity */}
        <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] pb-[24px]">
          <p className="text-lg font-Glacial text-[#111] pt-[16px]">
            {t("addNewDish.guestCapacity")}
            <span className="text-red-500">*</span>
          </p>
          <p className="flex">
            <span
              className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
            ></span>
          </p>
          <div className="pt-[24px]">
            <div className="relative flex  font-Glacial">
              <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0 border-gray-300 rounded-s-xl ">
                <GuestCapacity />
              </span>
              <input
                name="maxDiningCapacity"
                type="number"
                onWheel={(e) => {
                  e.target.blur();
                  e.stopPropagation();
                  setTimeout(() => {
                    e.target.focus();
                  }, 0);
                }}
                placeholder={t("addNewDish.enterGuestCapacity")}
                onChange={handleInputChange}
                className="pl-16 rounded-xl bg-white border text-gray-900  block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
              />
            </div>
            {lang === "en" || lang === "en-US" ? (
              <>
                {errors.maxDiningCapacity &&
                  dishData?.maxDiningCapacity === "" && (
                    <div className="text-red-300 font-Glacial ml-6 text-lg">
                      {errors.maxDiningCapacity}
                    </div>
                  )}
              </>
            ) : (
              <>
                {errorsEs.maxDiningCapacity &&
                  dishData?.maxDiningCapacity === "" && (
                    <div className="text-red-300 font-Glacial ml-6 text-lg">
                      {errorsEs.maxDiningCapacity}
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className=" pt-4 container mx-auto grid grid-cols-1 xl:grid-cols-3 gap-4">
        {/* display image */}
        <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
          <p className="text-lg font-Glacial text-[#111] ">
            {t("addNewDish.displayImg")}
            <span className="text-red-500">*</span>
          </p>
          <p className="flex">
            <span
              className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
            ></span>
          </p>
          <div className="flex  font-Glacial mt-3 ">
            <label className="relative w-full border rounded-lg h-40 ">
              {dishData?.mainImage ? (
                <div className="w-full justify-start items-center gap-2 rounded-lg rounded-e-xl bg-transparent  text-gray-900  min-w-0 text-lg border-gray-300 focus:ring-0 focus:border-gray-300">
                  <img
                    className="rounded-xl object-contain  ml-32 w-[180px] h-[120px] border mt-4 p-4 border-gray-300"
                    src={dishData?.mainImage}
                    alt="mainImage"
                    width={60}
                    height={60}
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleImageDelete();
                    }}
                    className="absolute top-2 right-2 bg-white text-primary rounded-full w-8 h-8 flex items-center justify-center opacity-0 hover:opacity-100 hover:bg-gray-200"
                  >
                    <IoCloseOutline size={24} />
                  </button>
                </div>
              ) : (
                <span className="rounded-none rounded-e-xl items-center justify-center  bg-white  text-gray-500  min-w-0 w-full  border-gray-300 ">
                  <div className="flex items-center gap-2 ">
                    <div className="flex rounded-lg  flex-col items-center justify-center text-center mt-10 ml-32 p-4  ">
                      <IoCloudUploadOutline size={40} />
                      {t("addNewDish.uploadMainImg")}
                    </div>
                  </div>
                </span>
              )}

              <input
                name="mainImage"
                type="file"
                onChange={handleInputChange1}
                className="absolute inset-0 opacity-0 h-full cursor-pointer rounded-none rounded-e-xl bg-white border text-gray-900 block flex-1 min-w-0 w-[300px] text-lg border-gray-300 p-2.5 focus:ring-0 focus:border-gray-300"
              />
            </label>
          </div>
          {lang === "en" || lang === "en-US" ? (
            <>
              {errors.mainImage && dishData?.mainImage === "" && (
                <div className="text-red-300 font-Glacial ml-6 text-lg">
                  {errors.mainImage}
                </div>
              )}
            </>
          ) : (
            <>
              {errorsEs.mainImage && dishData?.mainImage === "" && (
                <div className="text-red-300 font-Glacial ml-6 text-lg">
                  {errorsEs.mainImage}
                </div>
              )}
            </>
          )}

          {/* </label> */}
        </div>
        {/* upload food image */}
        <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
          <p className="text-lg font-Glacial text-[#111] ">
            {t("addNewDish.foodImgs")}
            <span className="text-red-500">*</span>
          </p>
          <p className="flex">
            <span
              className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
            ></span>
          </p>
          <label className="relative mt-3 w-full h-40 rounded-xl flex flex-col items-start justify-center cursor-pointer bg-white border">
            {dishData.otherImages.length > 0 && (
              <div className="absolute top-7 ">
                <div className="w-[100px] h-[100px] border border-gray-300 ml-5 flex items-center justify-center rounded-lg">
                  <IoCloudUploadOutline size={30} />
                </div>
              </div>
            )}

            {dishData?.otherImages && dishData.otherImages.length > 0 ? (
              <div
                className={`flex flex-row gap-2 pb-1 ml-32 ${
                  dishData.otherImages.length > 2 ? "overflow-x-auto" : ""
                }`}
              >
                {dishData.otherImages.map((image, index) => (
                  <div key={index} className="relative">
                    <div
                      className="flex justify-start items-center gap-2"
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                    >
                      <img
                        className="rounded-lg object-contain w-[100px] h-[100px] ml-5 hover:opacity-45 border border-gray-300 p-2"
                        src={image}
                        alt={`otherImage-${index}`}
                      />

                      {hoveredIndex === index && (
                        <div className="absolute top-0 right-0 cursor-pointer text-primary font-bold bg-gray-300 rounded-full p-2">
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveOtherImage(index);
                            }}
                          >
                            <RxCross1 />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <span className="mt-3 leading-normal text-[#ABABAB] font-Glacial ">
                <div className="flex flex-col items-center justify-center mx-auto ml-32">
                  <IoCloudUploadOutline size={40} />
                  {t("addNewDish.uploadFoodImgs")}
                </div>
              </span>
            )}

            <input
              name="otherImages"
              type="file"
              className="hidden border-0"
              onChange={handleInputChange}
            />
          </label>

          {dishData.otherImages.length === 1 && (
            <span className="mt-3 leading-normal text-red-300 text-lg ml-6 font-Glacial">
              {lang === "en" || lang === "en-US" ? (
                <p>Minimum 2 images required.</p>
              ) : (
                <p>Se requieren mínimo 2 imágenes.</p>
              )}
            </span>
          )}

          {lang === "en" || lang === "en-US" ? (
            <>
              {errors.otherImages && (
                <div className="text-red-300 font-Glacial ml-6 text-lg">
                  {errors.otherImages}
                </div>
              )}
            </>
          ) : (
            <>
              {errorsEs.otherImages && (
                <div className="text-red-300 font-Glacial ml-6 text-lg">
                  {errorsEs.otherImages}
                </div>
              )}
            </>
          )}
        </div>
        {/* upload place image */}
        <div className="border-[1px] border-gray-300 rounded-xl px-[16px] py-[24px]">
          <p className="text-lg font-Glacial text-[#111] ">
            {t("addNewDish.placeImgs")}
            <span className="text-red-500">*</span>
          </p>
          <p className="flex">
            <span
              className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
            ></span>
          </p>
          <label className="relative mt-3 w-full h-40 rounded-xl flex flex-col items-start   cursor-pointer bg-white border">
            <div className="absolute top-8">
              {dishData.placeImages.length > 0 && (
                <div className="w-[100px] h-[100px] border border-gray-300 ml-5 flex items-center justify-center rounded-lg">
                  <IoCloudUploadOutline size={30} />
                </div>
              )}
            </div>
            {dishData?.placeImages && dishData.placeImages.length > 0 ? (
              <div
                className={`flex flex-row gap-2 mt-8 ml-32 ${
                  dishData.placeImages.length > 2 ? "overflow-x-auto" : ""
                }`}
              >
                {dishData.placeImages.map((image, index) => (
                  <div key={index} className="relative">
                    <div
                      className="flex justify-start items-center gap-2"
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                    >
                      <img
                        className="rounded-lg object-contain w-[100px] h-[100px] ml-5 hover:opacity-45 border border-gray-300 p-2"
                        src={image}
                        alt={`placeImage-${index}`}
                        width={64}
                        height={64}
                      />

                      {hoveredIndex === index && (
                        <div className="absolute top-0 right-0 cursor-pointer text-primary font-bold bg-gray-200 rounded-full p-2 ">
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemovePlaceImage(index);
                            }}
                          >
                            <RxCross1 />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <span className="mt-3 leading-normal text-[#ABABAB] font-Glacial ">
                <div className="flex flex-col items-center justify-center mx-auto ml-32 mt-10">
                  <IoCloudUploadOutline size={40} />
                  {t("addNewDish.uploadPlaceImgs")}
                </div>
              </span>
            )}

            <input
              name="placeImages"
              type="file"
              className="hidden border-0"
              onChange={handleInputChange}
            />
          </label>
          {dishData.placeImages.length === 1 && (
            <span className="mt-3 leading-normal text-red-300 text-lg ml-6 font-Glacial">
              {lang === "en" || lang === "en-US" ? (
                <p>Minimum 2 images required.</p>
              ) : (
                <p>Se requieren mínimo 2 imágenes.</p>
              )}
            </span>
          )}

          {lang === "en" || lang === "en-US" ? (
            <>
              {errors.placeImages && (
                <div className="text-red-300 font-Glacial ml-6 text-lg">
                  {errors.placeImages}
                </div>
              )}
            </>
          ) : (
            <>
              {errorsEs.placeImages && (
                <div className="text-red-300 font-Glacial ml-6 text-lg">
                  {errorsEs.placeImages}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className=" pt-4 container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* food category */}
        <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
          <p className="text-lg font-Glacial text-[#111] pt-[16px]">
            {t("addNewDish.foodCategory")}
            <span className="text-red-500">*</span>
          </p>
          <p className="flex">
            <span
              className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
            ></span>
          </p>
          <div className="pt-[24px]">
            <div>
              <Dropdown
                labelName=""
                placeholder={t("addNewDish.foodCategory")}
                startIcon={<FoodCategory />}
                options={
                  lang === "en" || lang === "en-US"
                    ? configs.categoryEn
                    : configs.category
                }
                onChange={(val) =>
                  setDishData({ ...dishData, foodType: val.value })
                }
                size="small"
                name="foodType"
              />
              {lang === "en" || lang === "en-US" ? (
                <>
                  {errors.foodType && dishData?.foodType === "" && (
                    <div className="text-red-300 font-Glacial ml-6 text-lg">
                      {errors.foodType}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {errorsEs.foodType && dishData?.foodType === "" && (
                    <div className="text-red-300 font-Glacial ml-6 text-lg">
                      {errorsEs.foodType}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {/* food type */}
        <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
          <p className="text-lg font-Glacial text-[#111] pt-[16px]">
            {t("addNewDish.foodType")}
            <span className="text-red-500">*</span>
          </p>
          <p className="flex">
            <span
              className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
            ></span>
          </p>
          <div className="pt-[24px]">
            <div>
              <Dropdown
                name="foodClass"
                labelName=""
                placeholder={t("addNewDish.foodType")}
                startIcon={<FourSqure />}
                options={
                  lang === "en" || lang === "en-US"
                    ? [
                        { value: "Vegetariano", label: "Vegetarian" },
                        { value: "No Vegetariano", label: "Non-Vegetarian" },
                        { value: "Vegano", label: "Vegan" },
                      ]
                    : [
                        { value: "Vegetariano", label: "Vegetarian" },
                        { value: "No Vegetariano", label: "Non-Vegetarian" },
                        { value: "Vegano", label: "Vegano" },
                      ]
                }
                onChange={(val) => {
                  // console.log("val", val);
                  setDishData({
                    ...dishData,
                    foodClass: val.value,
                    enFoodClass: val.label,
                  });
                }}
                size="small"
              />
              {lang === "en" || lang === "en-US" ? (
                <>
                  {errors.foodClass && dishData?.foodClass === "" && (
                    <div className="text-red-300 font-Glacial ml-6 text-lg">
                      {errors.foodClass}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {errorsEs.foodClass && dishData?.foodClass === "" && (
                    <div className="text-red-300 font-Glacial ml-6 text-lg">
                      {errorsEs.foodClass}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {/* time */}
        <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
          <p className="text-lg font-Glacial text-[#111] pt-[16px]">
            {t("addNewDish.foodTime")}
            <span className="text-red-500">*</span>
          </p>
          <p className="flex">
            <span
              className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
            ></span>
          </p>
          <div className="flex font-Glacial pt-10 cursor-pointer">
            <div>
              <span className="inline-flex items-center p-4 text-sm text-gray-900 bg-white border-y border-l rounded-e-0 border-gray-300 rounded-s-xl">
                <FourSqure />
              </span>
            </div>

            <div className="  ">
              <Select
                className="xl:w-[420px] lg:w-[300px] h-[52px]  focus:ring-primary focus:border-primary text-gray-300 font-Glacial text-lg cursor-pointer border rounded-e-lg"
                name="mealTiming"
                labelName=""
                placeholder={t("addNewDish.foodTime")}
                options={
                  lang === "en" || lang === "en-US"
                    ? configs.timeEn
                    : configs.time
                }
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions.map(
                    (option) => option.value
                  );
                  setDishData({ ...dishData, mealTiming: selectedValues });
                }}
                components={{
                  DropdownIndicator: CustomDropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#fff",
                    primary: "#fff",
                  },
                })}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    cursor: "pointer",
                    border: 0,
                    boxShadow: "none",
                    color: "#fff",
                    borderRadius: "18px",
                    outline: "none",
                  }),
                  multiValueLabel: (styles) => ({
                    ...styles,
                    backgroundColor: "#FF914D",
                    color: "#fff",
                    borderRadius: "5px 0 0 5px",
                  }),
                  multiValueRemove: (styles) => ({
                    ...styles,
                    color: "#fff",
                    backgroundColor: "#FF914D",
                    outline: "none",
                    borderRadius: "0 5px 5px 0",
                    ":hover": {},
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    cursor: "pointer",
                    color: "#bfbfbf",
                    fontWeight: "lighter",
                    marginTop: "5px",
                    marginLeft: "2px",
                  }),
                  option: (styles) => ({
                    ...styles,
                    color: "#333",
                  }),
                }}
                isMulti
              />
              <div className="pt-[2px]"></div>
              {errors.mealTiming && (
                <div className="text-red-300 font-Glacial -ml-12 text-lg">
                  {errors.mealTiming}
                </div>
              )}
            </div>
          </div>
          {/*
          <div className="pt-[24px]">
            <div>
              <Dropdown
                name="mealTiming"
                labelName=""
                placeholder={t("addNewDish.foodType")}
                startIcon={<FourSqure />}
                options={
                  lang === "en" || lang === "en-US"
                    ? configs.timeEn
                    : configs.time
                }
                onChange={(val) => {
                  // console.log("val", val);
                  setDishData({
                    ...dishData,
                    mealTiming: val.value,
                  });
                }}
                size="small"
              />
              {lang === "en" || lang === "en-US" ? (
                <>
                  {errors.mealTiming && (
                    <div className="text-red-300 font-Glacial ml-6 text-lg">
                      {errors.mealTiming}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {errorsEs.mealTiming && (
                    <div className="text-red-300 font-Glacial ml-6 text-lg">
                      {errorsEs.mealTiming}
                    </div>
                  )}
                </>
              )}
            </div>
          </div> */}
        </div>
      </div>

      <div className="pt-4 container mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* description */}
        <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
          <p className="text-lg font-Glacial text-[#111] pt-[16px]">
            {t("addNewDish.dishDesc")}
            <span className="text-red-500">*</span>
          </p>
          <p className="flex">
            <span
              className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
            ></span>
          </p>
          <div className="pt-[12px]">
            <div className="relative flex pt-[21px] font-Glacial">
              <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0 border-gray-300 rounded-s-xl ">
                <FourSqure />
              </span>

              <input
                name="dishDescription"
                type="text"
                placeholder={t("addNewDish.enterDishDesc")}
                onChange={handleInputChange}
                className="pl-16 rounded-xl bg-white border text-gray-900  block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
              />
            </div>
            {lang === "en" || lang === "en-US" ? (
              <>
                {errors.dishDescription && dishData?.dishDescription === "" && (
                  <div className="text-red-300 font-Glacial ml-6 text-lg">
                    {errors.dishDescription}
                  </div>
                )}
              </>
            ) : (
              <>
                {errorsEs.dishDescription &&
                  dishData?.dishDescription === "" && (
                    <div className="text-red-300 font-Glacial ml-6 text-lg">
                      {errorsEs.dishDescription}
                    </div>
                  )}
              </>
            )}
          </div>

          <div className="pt-[10px]">
            <span className="text-black font-Glacial text-lg">
              {t("english")}
            </span>
          </div>

          <div className="relative flex pt-[10px] font-Glacial">
            <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0 border-gray-300 rounded-s-xl ">
              <FourSqure />
            </span>

            <input
              name="enDishDescription"
              type="text"
              placeholder={t("addNewDish.enterDishDesc")}
              onChange={handleInputChange}
              className="pl-16 transition duration-200 focus:ring-primary focus:border-primary rounded-xl bg-white border text-gray-900  block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5  "
            />
          </div>
        </div>
        {/* cancellation policy */}
        <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
          <p className="text-lg font-Glacial text-[#111] pt-[16px]">
            {t("addNewDish.cancellationPolicy")}
            <span className="text-red-500">*</span>
          </p>
          <p className="flex">
            <span
              className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
            ></span>
          </p>
          <div className="pt-[24px]">
            <div>
              <Dropdown
                name="cancellationPolicy"
                labelName=""
                placeholder={t("addNewDish.noCancel")}
                startIcon={<Cancelation />}
                options={
                  lang === "en" || lang === "en-US"
                    ? configs.cancelEn
                    : configs.cancel
                }
                onChange={(val) => {
                  // console.log("val", val);
                  setDishData({ ...dishData, cancellationPolicy: val.value });
                }}
                size="small"
              />
              {lang === "en" || lang === "en-US" ? (
                <>
                  {errors.cancellationPolicy &&
                    dishData?.cancellationPolicy === "" && (
                      <div className="text-red-300 font-Glacial ml-6 text-lg">
                        {errors.cancellationPolicy}
                      </div>
                    )}
                </>
              ) : (
                <>
                  {errorsEs.cancellationPolicy &&
                    dishData?.cancellationPolicy === "" && (
                      <div className="text-red-300 font-Glacial ml-6 text-lg">
                        {errorsEs.cancellationPolicy}
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="pt-4 container mx-auto grid grid-cols-1 gap-4">
        {/* accessibility */}
        <div className=" border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
          <div className="flex justify-start items-center gap-4">
            <div>
              <p className="text-lg font-Glacial text-[#111] ">
                {t("addNewDish.accesibility")}
                <span className="text-red-500">*</span>
              </p>
              <p className="flex">
                <span
                  className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                ></span>
              </p>
            </div>
          </div>
          <div className="text-[#ABABAB] pt-[21px] font-Glacial">
            <span>{t("addNewDish.mentionAcc")}</span>
          </div>
          <div className="container font-Glacial mx-auto mt-3">
            <div className="mb-4">
              <input
                type="text"
                className="w-1/2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-primary focus:ring-2 focus:ring-primary transition delay-100"
                placeholder={t("addNewDish.egStair")}
                value={inputText}
                onChange={handleInputChangeAcce}
                onKeyPress={handleInputKeyPress}
              />
            </div>
            <div className="flex flex-wrap">
              {labels.map((label, index) => (
                <div
                  key={index}
                  className="relative bg-primary text-white font-Glacial rounded-md pl-4 pr-10 py-1 mr-2 mb-2"
                >
                  {label}
                  <button
                    className="absolute top-1 right-2 mt-1 mr-1 focus:outline-none"
                    onClick={() => removeLabel(index)}
                  >
                    <RxCross1 />
                  </button>
                </div>
              ))}
            </div>
          </div>
          {lang === "en" || lang === "en-US" ? (
            <>
              {errors.placeAccessibility &&
                dishData?.placeAccessibility?.length === 0 && (
                  <div className="text-red-300 font-Glacial ml-6 text-lg">
                    {errors.placeAccessibility}
                  </div>
                )}
            </>
          ) : (
            <>
              {errorsEs.placeAccessibility &&
                dishData?.placeAccessibility?.length === 0 && (
                  <div className="text-red-300 font-Glacial ml-6 text-lg">
                    {errorsEs.placeAccessibility}
                  </div>
                )}
            </>
          )}
        </div>
      </div>

      <div className=" container mx-auto mb-[70px] pt-[32px] flex justify-end">
        {loading ? (
          <div className="px-[26px] py-[10px] text-lg rounded-full border-primary bg-white text-[#FFF]  border-[3px] w-[100px] h-[50px] ">
            <Loader size={25} />
          </div>
        ) : (
          <DBtn
            variant="contain"
            size="medium"
            onClick={handleSubmit}
            startIcon=""
          >
            {t("addNewDish.save")}
          </DBtn>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default DishForm;
