import React from "react";
import DBtn from "./DBtn";

const ChooseDateCard = () => {
  return (
    <div className="text-lg font-Glacial">
      <div className="pt-[40px]">
        <span>Choose date</span>
      </div>
      <div className="flex pt-[24px]">
        <div>
          <div className="flex items-center border-[1px] border-[#FF914D] rounded-full ">
            <div className="pl-[10px]">
              <span className="pr-[10px]">From</span>
            </div>
            <div>
              <DBtn
                variant="outline"
                size="small"
                // onClick={(e) => console.log("button click")}
                startIcon=""
              >
                14-06-2023
              </DBtn>
            </div>
          </div>
        </div>
        <div>
          <div className="flex items-center ml-[50px] border-[1px] border-[#FF914D] rounded-full">
            <div>
              <span className="pr-[10px] pl-[10px]">To</span>
            </div>
            <div>
              <DBtn
                variant="outline"
                size="small"
                // onClick={(e) => console.log("button click")}
                startIcon=""
              >
                14-06-2023
              </DBtn>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseDateCard;
