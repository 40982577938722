import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Title from "../components/Title";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import ShowCookies from "../components/ShowCookies";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import Loader from "../components/Loader";
import { getCookiesPolicy } from "../services/policyServices";
const CookiesPolicy = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const resp = await getCookiesPolicy();
      if (resp?.success) {
        setData(resp?.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };
  const lang = localStorage.getItem("i18nextLng");
  const goBack = () => {
    setLoading(true);
    navigate(-1);

    setTimeout(() => {
      window.scrollTo(0, 0);
      setLoading(false);
    }, 100);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [lang, navigate]);
  return (
    <>
      <div className="container mx-auto w-full">
        <ShowCookies />
        <Header />
        <div
          onClick={goBack}
          className="flex gap-2 items-center font-Glacial font-bold mb-4 cursor-pointer"
        >
          <IoArrowBackOutline size={20} />
          <p>{t("termsCondition.back")}</p>
        </div>
        <div className="font-bold">
          <Title title={t("cookiesPolicy.title")} />
        </div>
        {loading ? (
          <>
            <div className="flex justify-center items-center m-auto h-[50vh]">
              <Loader />
            </div>
          </>
        ) : (
          <>
            {/* content */}
            <div className="font-Glacial mt-4 text-xl">
              {lang === "en" || lang === "en-US" ? (
                <div>
                  {/* ENGLISH */}
                  {data?.translations?.en?.policyDetails?.policyDesc && (
                    <div>
                      {" "}
                      {parse(data?.translations?.en?.policyDetails?.policyDesc)}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {/* SPANISH */}
                  {data?.policyDetails?.policyDesc && (
                    <div> {parse(data?.policyDetails?.policyDesc)}</div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default CookiesPolicy;
