import React, { useState } from "react";
import { forgotPassword } from "../services/authService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ShowCookies from "../components/ShowCookies";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [errorsEs, setErrorsEs] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    const newErrorsEs = {};

    if (!userData.email) {
      newErrorsEs.email = "correo electronico es requerido";
      newErrors.email = "Email is required";
      isValid = false;
    }
    setErrorsEs(newErrorsEs);
    setErrors(newErrors);
    return isValid;
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const payload = {
        email: userData?.email,
      };

      try {
        const resp = await forgotPassword(payload);

        if (resp?.success) {
          toast.success(resp?.message);
          localStorage.setItem("forgotEmail", userData?.email);
          navigate("/verify-forgot-otp");
        }
      } catch (err) {
        console.error("err", err);
        toast.error(err);
      }
    } else {
      // Form is not valid, display error messages
    }
  };

  return (
    <div className="pt-[100px]">
      <div className="container mx-auto">
        <ShowCookies />
      </div>
      <div className="container border-[1px] border-[#FF914D] w-[520px] h-[550px] text-center pt-[30px] rounded-md  mx-auto bg-[#FFF] px-[24px] mt-[80px]">
        <div className="pt-[40px] ">
          <div className="w-[100px] h-[100px] mx-auto">
            <img src="../images/OTP-LOGO.png" alt="" />
          </div>
          {lang === "en" || lang === "en-US" ? (
            <div>
              <h3 className="font-Comfortaa text-[24px] font-bold text-[#111] text-center pt-[16px]">
                Welcome!
              </h3>
              <h5 className="text-[#757575] font-Glacial Indifference text-[16px] text-center pt-[4px]">
                Login your account
              </h5>
            </div>
          ) : (
            <div>
              <h3 className="font-Comfortaa text-[24px] font-bold text-[#111] text-center pt-[16px]">
                Bienvenida
              </h3>
              <h5 className="text-[#757575] font-Glacial Indifference text-[16px] text-center pt-[4px]">
                Inicie sesión en su cuenta
              </h5>
            </div>
          )}
        </div>
        <form>
          <div>
            <div className="relative flex items-center pt-4 pb-2">
              <div className="absolute text-2xl text-primary p-3 flex items-center justify-center only: w-[70px] h-[70px] bg-transparent border-r">
                <img src="../images/Loginform/maile.svg" alt="" />
              </div>
              <input
                id="email"
                name="email"
                className="w-full mx-auto h-[70px] border border-gray-300 focus:ring-2 focus:ring-primary focus:border-primary transition delay-150 outline-none rounded-lg ps-[84px] pe-4 font-Glacial  sm:text-[20px] text-[12px]  placeholder:text-[#ABABAB]"
                type="email"
                placeholder={t("homePage.enterEmail")}
                onChange={handleInputChange}
              />
            </div>
            {lang === "en" || lang === "en-US" ? (
              <>
                {errors.email && userData?.email === "" && (
                  <div className="text-red-300">{errors.email}</div>
                )}
              </>
            ) : (
              <>
                {errorsEs.email && userData?.email === "" && (
                  <div className="text-red-300">{errorsEs.email}</div>
                )}
              </>
            )}
          </div>

          <div className="flex justify-center pt-[72px] ">
            <button
              onClick={handleForgotPasswordSubmit}
              variant="contain"
              type="submit"
              className="bg-primary text-white py-[18px] w-[396px] rounded-full mx-auto  font-Comfortaa-Medium text-[16px] "
            >
              {t("login")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
