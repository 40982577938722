import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { updateUserProfile } from "../services/profileService";

const AddLocationDetails = ({ setLocationModal, setAddDishModal }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const [locationLoading, setLocationLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [locationDetails, setLocationDetails] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!locationDetails.addressLine1) {
      newErrors.addressLine1 =
        lang === "en" || lang === "en-US"
          ? "Address Line 1 is required."
          : "Se requiere la línea de dirección 1.";
    }

    if (!locationDetails.addressLine2) {
      newErrors.addressLine2 =
        lang === "en" || lang === "en-US"
          ? "Address Line 1 required."
          : "Se requiere la línea de dirección 2.";
    }

    if (!locationDetails.city) {
      newErrors.city =
        lang === "en" || lang === "en-US"
          ? "City is required."
          : "Se requiere ciudad.";
    }

    if (!locationDetails.state) {
      newErrors.state =
        lang === "en" || lang === "en-US"
          ? "State is required."
          : "Se requiere Estado.";
    }

    if (!locationDetails.country) {
      newErrors.country =
        lang === "en" || lang === "en-US"
          ? "Country is required."
          : "Se requiere país.";
    }

    if (!locationDetails.postalCode) {
      newErrors.postalCode =
        lang === "en" || lang === "en-US"
          ? "Pincode is required."
          : "Se requiere código PIN.";
    }

    return newErrors;
  };

  const handleBankDetails = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLocationLoading(true);
    try {
      const payload = {
        address: {
          addressLine1: locationDetails.addressLine1,
          addressLine2: locationDetails.addressLine2,
          city: locationDetails.city,
          state: locationDetails.state,
          country: locationDetails.country,
          postalCode: locationDetails.postalCode,
        },
      };
      const resp = await updateUserProfile(payload);
      if (resp?.success) {
        setAddDishModal(true);
        setLocationModal(false);
      }
    } catch (error) {
      console.error("err:", error);
    } finally {
      setLocationLoading(false);
    }
  };

  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg w-[90%] min-w-[310px] max-w-[800px] relative">
          <div className="p-6 text-center mt-4">
            <div className="flex justify-center">
              <img src="../images/Loginform/logo.svg" alt="" />
            </div>

            <p className="flex justify-center font-Comfortaa-Bold text-2xl text-[#111] mt-2 mb-1">
              {t("homePage.welcome")}
            </p>

            <span className="flex justify-center text-[16px] font-Glacial text-[#757575]">
              {t("profilePage.addLocationInfo")}
            </span>

            <div className="my-10">
              <div
                className={`grid grid-cols-1 md:grid-cols-2 gap-4 ${
                  errors.addressLine1 || errors.addressLine2 ? "mt-2" : "mt-10"
                }
  `}
              >
                <div className="relative grid  pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/country.svg" alt="" />
                  </span>
                  <div>
                    <input
                      type="text"
                      name="addressLine1"
                      value={locationDetails.addressLine1}
                      onChange={handleChange}
                      placeholder={t("profilePage.addadd1")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />

                    {errors.addressLine1 && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.addressLine1}
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative grid pt-[0px] font-Glacial pb-3">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/country.svg" alt="" />
                  </span>
                  <div>
                    <input
                      type="text"
                      name="addressLine2"
                      value={locationDetails.addressLine2}
                      onChange={handleChange}
                      placeholder={t("profilePage.addadd2")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />{" "}
                    {errors.addressLine2 && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.addressLine2}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`grid grid-cols-1 md:grid-cols-2 gap-4 ${
                  errors.city || errors.state ? "mt-2" : "mt-10"
                }
  `}
              >
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/cityLoc.svg" alt="" />
                  </span>
                  <div>
                    <input
                      type="text"
                      name="city"
                      value={locationDetails.city}
                      onChange={handleChange}
                      placeholder={t("profilePage.enterCity")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />{" "}
                    {errors.city && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.city}
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/stateLoc.svg" alt="" />
                  </span>{" "}
                  <div>
                    <input
                      type="text"
                      name="state"
                      value={locationDetails.state}
                      onChange={handleChange}
                      placeholder={t("profilePage.enterState")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />{" "}
                    {errors.state && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.state}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`grid grid-cols-1 md:grid-cols-2 gap-4 ${
                  errors.country || errors.postalCode ? "mt-2" : "mt-10"
                }
  `}
              >
                <div className="relative grid pt-[0px] font-Glacial pb-3">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/country.svg" alt="" />
                  </span>{" "}
                  <div>
                    <input
                      type="text"
                      name="country"
                      value={locationDetails.country}
                      onChange={handleChange}
                      placeholder={t("profilePage.enterYourCountry")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />
                    {errors.country && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.country}
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative grid pt-[0px] font-Glacial pb-3">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/pinLoc.svg" alt="" />
                  </span>{" "}
                  <div>
                    <input
                      type="text"
                      name="postalCode"
                      value={locationDetails.postalCode}
                      onChange={handleChange}
                      placeholder={t("profilePage.enterPin")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />
                    {errors.postalCode && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.postalCode}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="bottom-6 right-6 flex gap-4 justify-end">
              <button
                onClick={handleBankDetails}
                disabled={locationLoading}
                className={`bg-primary font-Comfortaa-Medium text-white py-2 px-4 rounded-full font-medium text-sm hover:bg-primary-dark focus:outline-none ${
                  locationLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {locationLoading
                  ? lang === "en" || lang === "en-US"
                    ? "Saving..."
                    : "Ahorro..."
                  : lang === "en" || lang === "en-US"
                  ? "Save and Next"
                  : "Guardar y Siguiente"}
              </button>
              <button
                className="bg-gray-300 font-Comfortaa-Medium text-gray-700 py-2 px-4 rounded-full font-medium text-sm hover:bg-gray-400 focusbaseutline-none"
                onClick={() => {
                  setLocationModal(false);
                  setAddDishModal(true);
                }}
              >
                {lang === "en" || lang === "en-US" ? "Skip" : "Saltar"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLocationDetails;
