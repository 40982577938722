import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { postAvailability } from "../services/profileService";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import PlusIcon from "../svg/PlusIcon";
import DeleteIcon from "../svg/DeleteIcon";
import { useSelector } from "react-redux";

const AddAvailabilityDetails = ({ setAddAvailabilityModal, fetchData }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const lang = localStorage.getItem("i18nextLng");
  const [loading, setLoading] = useState(false);
  const [initDate, setInitDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [timeRanges, setTimeRanges] = useState([
    { startTime: "9:00 am", endTime: "10:00 am" },
  ]);
  const handleAddTime = () => {
    setTimeRanges([
      ...timeRanges,
      { startTime: "9:00 am", endTime: "10:00 am" },
    ]);
  };
  const handleStartTimeChange = (value, index) => {
    const updatedTimeRanges = [...timeRanges];
    updatedTimeRanges[index].startTime = value;
    setTimeRanges(updatedTimeRanges);
  };

  const handleEndTimeChange = (value, index) => {
    const updatedTimeRanges = [...timeRanges];
    updatedTimeRanges[index].endTime = value;
    setTimeRanges(updatedTimeRanges);
  };
  const timeOptions = [];
  for (let hour = 9; hour <= 12; hour++) {
    timeOptions.push(`${hour}:00 am`);
    timeOptions.push(`${hour}:30 am`);
  }

  for (let hour = 1; hour <= 9; hour++) {
    timeOptions.push(`${hour}:00 pm`);
    timeOptions.push(`${hour}:30 pm`);
  }

  const handleDeleteTime = (index) => {
    const updatedTimeRanges = [...timeRanges];
    updatedTimeRanges.splice(index, 1);
    setTimeRanges(updatedTimeRanges);
  };

  const addHostAvailability = async () => {
    const payload = {
      dates: [
        {
          userId: user?.id,
          startDate: initDate[0]?.startDate,
          endDate: initDate[0]?.endDate,
          timeSlots: timeRanges.map((timeRange) => ({
            startTime: timeRange.startTime,
            endTime: timeRange.endTime,
          })),
        },
      ],
    };

    // console.log(" payload :::", payload);
    try {
      setLoading(true);
      const resp = await postAvailability(payload);
      if (resp?.success) {
        // setIsModalOpen(false);
        setAddAvailabilityModal(false);
        fetchData();
      }
    } catch (error) {
      console.error("AddHostAvailability: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg w-[90%] min-w-[310px] max-w-[400px] relative">
          <div className="p-6 text-center mt-4">
            <div className="flex justify-center">
              <img src="../images/Loginform/logo.svg" alt="" />
            </div>
            <p className="flex justify-center font-Comfortaa-Bold text-2xl text-[#111] mt-2 mb-1">
              {t("homePage.welcome")}
            </p>
            <span className="flex justify-center text-[16px] font-Glacial text-[#757575]">
              {t("profilePage.addAvailability")}
            </span>
            <div className="my-5">
              <h1 className="font-Glacial text-left">
                {t("avalability.chooseDate")}
              </h1>
              <DateRange
                className="border p-4 rounded-md shadow-md"
                editableDateInputs={true}
                onChange={(item) => setInitDate([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={initDate}
                rangeColors={["#FF914D"]}
              />

              <div className="flex gap-10 mt-5">
                <div className="flex border border-primary rounded-full">
                  <div className="p-2">{t("avalability.from")}</div>
                  <div className="border-l p-2 border-primary rounded-full">
                    {format(initDate[0].startDate, "dd-MM-yyyy")}
                  </div>
                </div>
                <div className="flex border border-primary rounded-full">
                  <div className="p-2">{t("avalability.to")}</div>
                  <div className="border-l p-2 border-primary rounded-full">
                    {format(initDate[0].endDate, "dd-MM-yyyy")}
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <h1 className="flex items-center justify-between">
                  {t("avalability.chooseTime")}
                  <div
                    className="cursor-pointer font-bold"
                    onClick={handleAddTime}
                  >
                    <PlusIcon />
                  </div>
                </h1>
                <div className="overflow-y-auto max-h-[130px]">
                  {timeRanges?.length === 0 ? (
                    <div className="text-red-300 text-left">
                      {t("avalability.pleaseAdd")}
                    </div>
                  ) : (
                    timeRanges?.map((timeRange, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between mt-5"
                      >
                        <div className="flex items-center gap-5">
                          <select
                            placeholder="Start Time"
                            value={timeRange?.startTime}
                            onChange={(e) =>
                              handleStartTimeChange(e.target.value, index)
                            }
                            className="focus:outline-none w-[140px] border px-4 py-2 border-primary rounded-full"
                          >
                            {timeOptions.map((time, optionIndex) => (
                              <option key={optionIndex} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                          -
                          <select
                            placeholder="End Time"
                            value={timeRange?.endTime}
                            onChange={(e) =>
                              handleEndTimeChange(e.target.value, index)
                            }
                            className="focus:outline-none w-[140px] border px-4 py-2 border-primary rounded-full"
                          >
                            {timeOptions.map((time, optionIndex) => (
                              <option key={optionIndex} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="flex items-center gap-8">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleDeleteTime(index)}
                          >
                            <DeleteIcon />
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>

            <div className="bottom-6 right-6 flex gap-4 justify-end">
              <button
                onClick={addHostAvailability}
                disabled={loading}
                className={`bg-primary font-Comfortaa-Medium text-white py-2 px-4 rounded-full font-medium text-sm hover:bg-primary-dark focus:outline-none ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {loading
                  ? lang === "en" || lang === "en-US"
                    ? "Saving..."
                    : "Ahorro..."
                  : lang === "en" || lang === "en-US"
                  ? "Save and Next"
                  : "Guardar y Siguiente"}
              </button>
              <button
                className="bg-gray-300 font-Comfortaa-Medium text-gray-700 py-2 px-4 rounded-full font-medium text-sm hover:bg-gray-400 focusbaseutline-none"
                onClick={() => {
                  setAddAvailabilityModal(false);
                  fetchData();
                }}
              >
                {lang === "en" || lang === "en-US" ? "Skip" : "Saltar"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAvailabilityDetails;
