import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Searchbar from "../components/Searchbar";
import ChatUpdates from "../components/ChatUpdates";
import { useSelector } from "react-redux";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import Loader from "../components/Loader";
import Title from "../components/Title";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { timeAgo } from "../utils/CommonFunctions";

const ChatBox = () => {
  const { user } = useSelector((state) => state?.auth);
  const { chat } = useSelector((state) => state?.chat);
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");

  const [uniqueNames, setUniqueNames] = useState([]);
  const [messages, setMessages] = useState([]);
  const [matchedMessages, setMatchedMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [chatLoading, setChatLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [message, setMessage] = useState("");
  const [filteredNames, setFilteredNames] = useState(uniqueNames);
  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = uniqueNames?.filter(
      (item) =>
        item?.name && item?.name?.toLowerCase()?.includes(query.toLowerCase())
    );

    setFilteredNames(filtered);
  };

  const fetchMessages = async (e) => {
    // setChatLoading(true);
    try {
      if (user && chat) {
        let chatDocID = [user?.id, chat?.uid].join("_");
        // console.log("chatDocID", chatDocID);
        const chatDocRef = doc(db, "chats", chatDocID);

        const chatDoc = await getDoc(chatDocRef);
        // console.log("chatDoc", chatDoc);
        if (chatDoc.exists()) {
          const subCollectionRef = collection(
            db,
            `chats/${chatDocID}/${chatDocID}`
          );

          const subCollectionSnapshot = await getDocs(subCollectionRef);

          const messages = subCollectionSnapshot?.docs?.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setMessages(messages);
        } else {
          // console.log("No such document found!");

          chatDocID = [chat?.uid, user?.id].join("_");
          // console.log("Trying alternative chatDocID", chatDocID);
          const altChatDocRef = doc(db, "chats", chatDocID);
          const altChatDoc = await getDoc(altChatDocRef);

          if (altChatDoc.exists()) {
            const subCollectionRef = collection(
              db,
              `chats/${chatDocID}/${chatDocID}`
            );

            const subCollectionSnapshot = await getDocs(subCollectionRef);

            const messages = subCollectionSnapshot?.docs?.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            setMessages(messages);
          } else {
            // console.log(
            //   "No document found with alternative chatDocID as well!"
            // );
          }
        }
      } else if (user && selectedUser) {
        let chatDocID = [user?.id, selectedUser?.uid].join("_");
        // console.log("chatDocID", chatDocID);
        const chatDocRef = doc(db, "chats", chatDocID);

        const chatDoc = await getDoc(chatDocRef);
        // console.log("chatDoc", chatDoc);
        if (chatDoc.exists()) {
          const subCollectionRef = collection(
            db,
            `chats/${chatDocID}/${chatDocID}`
          );

          const subCollectionSnapshot = await getDocs(subCollectionRef);

          const messages = subCollectionSnapshot?.docs?.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setMessages(messages);
        } else {
          // console.log("No such document found!");

          chatDocID = [selectedUser?.uid, user?.id].join("_");
          // console.log("Trying alternative chatDocID", chatDocID);
          const altChatDocRef = doc(db, "chats", chatDocID);
          const altChatDoc = await getDoc(altChatDocRef);

          if (altChatDoc.exists()) {
            const subCollectionRef = collection(
              db,
              `chats/${chatDocID}/${chatDocID}`
            );

            const subCollectionSnapshot = await getDocs(subCollectionRef);

            const messages = subCollectionSnapshot?.docs?.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            setMessages(messages);
          } else {
            // console.log(
            //   "No document found with alternative chatDocID as well!"
            // );
          }
        }
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      // setChatLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const messagesRef = collection(db, "chats");
    setIsLoading(true);

    const unsubscribe = onSnapshot(messagesRef, (snapshot) => {
      const messagesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // console.log("Total messagesList", messagesList);

      const userMessages = messagesList?.filter((message) => {
        return message?.uidList && message?.uidList.includes(user.id);
      });

      //   console.log("userMessages", userMessages);

      const filteredUsers = userMessages.reduce((users, message) => {
        message?.users?.forEach((u) => {
          if (
            u.uid !== user.id &&
            !users.some((existingUser) => existingUser.uid === u.uid)
          ) {
            users.push(u);
          }
        });

        return users;
      }, []);
      // console.log(`filteredUsers with host id: ${user?.id}`, filteredUsers);

      setUniqueNames(filteredUsers);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  const handleUserClick = async (CurrUser) => {
    try {
      // console.log("insie handleUserClick", CurrUser);
      setSelectedUser(CurrUser);
      setMessage("");
      setMessages([]);
      // debugger;
      fetchMessages();
      if (user && chat) {
        let chatDocID = [user?.id, chat?.uid].join("_");
        // console.log("chatDocID", chatDocID);
        const chatDocRef = doc(db, "chats", chatDocID);

        const chatDoc = await getDoc(chatDocRef);
        // console.log("chatDoc", chatDoc);
        if (chatDoc.exists()) {
          await updateDoc(chatDocRef, {
            lastMessageRead: true,
          });
          // console.log("updated cdocument", chatDocID);
          // debugger;
          // fetchMessages();
          const updatedMatchedMessages = matchedMessages.map(
            (matchedMessage) => {
              if (matchedMessage.id === CurrUser.uid) {
                return {
                  ...matchedMessage,
                  lastMessageRead: true,
                };
              } else {
                return matchedMessage;
              }
            }
          );

          setMatchedMessages(updatedMatchedMessages);
        } else {
          // console.log("No such document found!");

          chatDocID = [chat?.uid, user?.id].join("_");
          // console.log("chatDocID", chatDocID);
          // console.log("Trying alternative chatDocID", chatDocID);
          const altChatDocRef = doc(db, "chats", chatDocID);
          const altChatDoc = await getDoc(altChatDocRef);
          // console.log("altChatDoc", altChatDoc);
          if (altChatDoc.exists()) {
            await updateDoc(altChatDocRef, {
              lastMessageRead: true,
            });
            // console.log("updated cdocument", chatDocID);
            // debugger;
            // fetchMessages();
            const updatedMatchedMessages = matchedMessages.map(
              (matchedMessage) => {
                if (matchedMessage.id === CurrUser.uid) {
                  return {
                    ...matchedMessage,
                    lastMessageRead: true,
                  };
                } else {
                  return matchedMessage;
                }
              }
            );

            setMatchedMessages(updatedMatchedMessages);
          } else {
            // console.log(
            //   "No document found with alternative chatDocID as well!"
            // );
          }
        }
      } else if (user && CurrUser) {
        // console.log("CurrUser", CurrUser);
        let chatDocID = [CurrUser?.uid, user?.id].join("_");
        // console.log("chatDocID", chatDocID);
        const chatDocRef = doc(db, "chats", chatDocID);
        // console.log("chatDocRef", chatDocRef);
        const chatDoc = await getDoc(chatDocRef);
        // console.log("chatDoc", chatDoc);
        if (chatDoc.exists()) {
          await updateDoc(chatDocRef, {
            lastMessageRead: true,
          });
          // console.log("updated cdocument", chatDocID);
          // debugger;
          // fetchMessages();
          const updatedMatchedMessages = matchedMessages?.map(
            (matchedMessage) => {
              if (matchedMessage.id === CurrUser.uid) {
                return {
                  ...matchedMessage,
                  lastMessageRead: true,
                };
              } else {
                return matchedMessage;
              }
            }
          );

          setMatchedMessages(updatedMatchedMessages);
        } else {
          // console.log("No such document found!");

          chatDocID = [CurrUser?.uid, user?.id].join("_");

          // console.log("chatDocID", chatDocID);
          const altChatDocRef = doc(db, "chats", chatDocID);
          const altChatDoc = await getDoc(altChatDocRef);
          // console.log("altChatDocRef", altChatDocRef);
          if (altChatDoc.exists()) {
            await updateDoc(chatDocRef, {
              lastMessageRead: true,
            });
            // console.log("updated cdocument", chatDocID);
            // debugger;
            // fetchMessages();
            const updatedMatchedMessages = matchedMessages.map(
              (matchedMessage) => {
                if (matchedMessage.id === CurrUser.uid) {
                  return {
                    ...matchedMessage,
                    lastMessageRead: true,
                  };
                } else {
                  return matchedMessage;
                }
              }
            );

            setMatchedMessages(updatedMatchedMessages);
          } else {
            // console.log(
            //   "No document found with alternative chatDocID as well!"
            // );
          }
        }
      }
    } catch (error) {
      console.error("first error: ", error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [selectedUser]);

  useEffect(() => {
    const fetchChats = async () => {
      const chatsCollection = collection(db, "chats");
      const chatsSnapshot = await getDocs(chatsCollection);
      const chatsList = chatsSnapshot?.docs?.map((doc) => doc.data());
      return chatsList;
    };

    const matchAndStoreMessages = async () => {
      const storedId = user?.id;
      const chats = await fetchChats();

      let matchedMessagesArray = [];

      chats.forEach((chat) => {
        if (chat.uidList.includes(storedId)) {
          const otherIds = chat?.uidList?.filter((uid) => uid !== storedId);
          otherIds.forEach((otherId) => {
            matchedMessagesArray?.push({
              id: otherId,
              lastMessage: chat?.lastMessage,
              lastMessageTime: chat?.lastMessageTime,
              lastMessageRead: chat?.lastMessageRead,
            });
          });
        }
      });

      setMatchedMessages(matchedMessagesArray);
    };

    matchAndStoreMessages();
  }, []);

  matchedMessages?.forEach((matchedMessage) => {
    const index = uniqueNames.findIndex(
      (user) => user.uid === matchedMessage.id
    );
    if (index !== -1) {
      uniqueNames[index] = {
        ...uniqueNames[index],
        lastMessage: matchedMessage.lastMessage,
        lastMessageTime: timeAgo(matchedMessage.lastMessageTime),
        lastMessageRead: matchedMessage.lastMessageRead,
      };
    }
  });

  // console.log("matchedMessages", matchedMessages);
  // console.log("uniqueNames", uniqueNames);
  return (
    <>
      <div className="container mx-auto w-full">
        <div className="border-b-[1px] border-[#FF914D]">
          <Header />
        </div>

        <div className=" flex  flex-col lg:flex-row ">
          <div className="left mt-5 mx-5 shadow-sm rounded-xl">
            <Title title={t("chat.chatHeader")} />
            <div className="mt-5">
              <Searchbar
                onChange={handleSearchInputChange}
                value={searchQuery}
              />
            </div>
            {isLoading ? (
              <div className="flex justify-center items-center m-auto h-[50vh]">
                <Loader />
              </div>
            ) : (
              <div className="max-h-[615px] h-[500px] overflow-y-auto mt-5 rounded-md  w-[400px] lg:w-full">
                {uniqueNames?.length === 0 ? (
                  <span className="font-Glacial text-2xl text-gray-500 flex flex-col items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="100"
                      height="100"
                      fill="#fcb386"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-9h2v6h-2v-6zm0-4h2v2h-2V7z" />
                    </svg>
                    No chats found
                  </span>
                ) : (
                  <>
                    {filteredNames?.length > 0 ? (
                      <>
                        {filteredNames.map((item, index) => {
                          const isSelected = selectedUser === item;
                          return (
                            <div
                              key={index}
                              className={`container mx-auto m-2 cursor-pointer ${
                                isSelected ? "bg-[#FF914D10]" : ""
                              }`}
                              onClick={() => handleUserClick(item)}
                            >
                              <div className="sm:w-[390px] py-[10px] px-6 flex justify-between">
                                <div className="flex items-center gap-x-3">
                                  <div>
                                    <img
                                      src={item?.image}
                                      alt=""
                                      className="w-8 h-8 rounded-full"
                                    />
                                  </div>
                                  <div>
                                    <samp className="text-[18px] font-Comfortaa font-bold text-[#474747]">
                                      {item?.name}
                                    </samp>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {uniqueNames?.map((item, index) => {
                          const isSelected = selectedUser === item;
                          return (
                            <>
                              <div
                                key={index}
                                className={`container mx-auto m-2 cursor-pointer   ${
                                  isSelected ? "bg-[#FF914D10] " : ""
                                }`}
                                onClick={() => handleUserClick(item)}
                              >
                                <div className="sm:w-[390px]   py-[10px] px-6 flex justify-between">
                                  <div className="flex items-center gap-x-3 ">
                                    <div>
                                      <img
                                        src={item?.image}
                                        alt=""
                                        className="w-8 h-8 rounded-full"
                                      />
                                    </div>
                                    <div>
                                      <samp className="text-[18px] font-Comfortaa font-bold text-[#474747]">
                                        {item?.name}
                                      </samp>
                                      <p className="text-xs text-[#ABABAB] font-Glacial">
                                        {item?.lastMessage}
                                      </p>
                                    </div>
                                  </div>
                                  {item?.lastMessageRead === false && (
                                    <div>
                                      <p className="text-primary font-Glacial text-[10px]">
                                        {item?.lastMessageTime}
                                      </p>
                                      <div className="flex justify-end">
                                        <p className="text-sm font-Glacial w-2 h-2 text-[#fff] bg-primary rounded-full "></p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div className="w-[1px] bg-primary"></div>
          <div className="right mt-5 mx-5">
            {selectedUser ? (
              <div>
                <div className="container mx-auto">
                  <div className="sm:w-[602px] lg:w-[792px] font-bold bg-[#FF914D10] py-[10px] px-[48px] flex justify-between rounded-t-lg">
                    <div className="flex items-center gap-x-3">
                      <div>
                        <img
                          src={selectedUser?.image}
                          alt=""
                          className="w-8 h-8 rounded-full"
                        />
                      </div>
                      <div>
                        <samp className="text-[18px] font-Comfortaa font-bold text-[#474747]">
                          {selectedUser?.name}
                        </samp>
                        {/* <p className="text-xs text-[#ABABAB] font-Glacial">
                        
                        {selectedUser.uid}
                      </p> */}
                      </div>
                    </div>
                  </div>
                  <div>
                    <ChatUpdates
                      // chatLoading={chatLoading}
                      setMessages={setMessages}
                      fetchMessages={fetchMessages}
                      messages={messages}
                      currentUser={user}
                      selectedUser={selectedUser}
                      message={message}
                      setMessage={setMessage}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {user?.role === "host" ? (
                  <>
                    <div className="sm:w-[792px] h-[660px] bg-[#FF914D10] py-[10px] px-[48px] flex justify-center items-center rounded-xl ">
                      <div className="flex flex-col items-center text-gray-600  ">
                        {lang === "en" || lang === "en-US" ? (
                          <p className="font-Glacial font-bold text-2xl">
                            Please select chat.
                          </p>
                        ) : (
                          <p className="font-Glacial font-bold text-2xl">
                            Por favor seleccione chatear.
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    {chat ? (
                      <div className="container mx-auto">
                        <div className="sm:w-[780px] lg:w-[792px] bg-[#FF914D10] py-[10px] px-[48px] flex justify-between rounded-t-lg">
                          <div className="flex items-center gap-x-3">
                            <div>
                              <img
                                src={chat?.image}
                                alt=""
                                className="w-8 h-8 rounded-full"
                              />
                            </div>
                            <div>
                              <samp className="text-[18px] font-bold font-Comfortaa text-[#474747]">
                                {chat?.name}
                              </samp>
                              {/* <p className="text-xs text-[#ABABAB] font-Glacial">
                              {selectedUser.uid}
                            </p> */}
                            </div>
                          </div>
                        </div>
                        <div>
                          <ChatUpdates
                            // chatLoading={chatLoading}
                            setMessages={setMessages}
                            fetchMessages={fetchMessages}
                            messages={messages}
                            currentUser={user}
                            selectedUser={chat}
                            message={message}
                            setMessage={setMessage}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        {selectedUser ? (
                          <div>
                            <div className="container mx-auto">
                              <div className="sm:w-[602px] lg:w-[792px] font-bold bg-[#FF914D10] py-[10px] px-[48px] flex justify-between rounded-t-lg">
                                <div className="flex items-center gap-x-3">
                                  <div>
                                    <img
                                      src={selectedUser?.image}
                                      alt=""
                                      className="w-8 h-8 rounded-full"
                                    />
                                  </div>
                                  <div>
                                    <samp className="text-[18px] font-Comfortaa font-bold text-[#474747]">
                                      {selectedUser?.name}
                                    </samp>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <ChatUpdates
                                  // chatLoading={chatLoading}
                                  setMessages={setMessages}
                                  fetchMessages={fetchMessages}
                                  messages={messages}
                                  currentUser={user}
                                  selectedUser={selectedUser}
                                  message={message}
                                  setMessage={setMessage}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="sm:w-[792px] h-[660px] bg-[#FF914D10] py-[10px] px-[48px] flex justify-center items-center rounded-xl ">
                              <div className="flex flex-col items-center text-gray-600  ">
                                {lang === "en" || lang === "en-US" ? (
                                  <p className="font-Glacial font-bold text-2xl">
                                    Please select chat.
                                  </p>
                                ) : (
                                  <p className="font-Glacial font-bold text-2xl">
                                    Por favor seleccione chatear.
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ChatBox;
