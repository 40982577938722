import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { getCookiesPolicy } from "../services/policyServices";

const CookieModal = ({ onClose, onSave }) => {
  const lang = localStorage.getItem("i18nextLng");
  // console.log("lang changed to " + lang);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const fetchData = async () => {
    try {
      setLoading(true);
      const resp = await getCookiesPolicy();
      if (resp?.success) {
        setData(resp?.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  // console.log("data", data);
  return (
    <>
      {loading ? (
        <></>
      ) : (
        <div className=" container mx-auto mb-5 h-full rounded-lg">
          {lang === "en" || lang === "en-US" ? (
            <div className="bg-white p-4 rounded-lg shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] font-Glacial ">
              <button className="absolute right-7 z-50" onClick={onClose}>
                <div className="w-[30px] h-[30px] bg-[#FF914D1A] p-[8px] text-primary rounded-full">
                  <AiOutlineClose />
                </div>
              </button>
              <h2 className="text-2xl font-bold mb-2">Privacy summary</h2>
              <div className="md:flex block gap-10 w-full">
                <p className="mb-2 w-5/6 text-sm">
                  {data?.translations?.en?.policyDetails?.policySummary && (
                    <div>
                      {" "}
                      {parse(
                        data?.translations?.en?.policyDetails?.policySummary
                      )}
                    </div>
                  )}
                </p>
                <div className="w-1/6 flex justify-start md:flex-col gap-2 ">
                  <button
                    className="mr-2 px-4 py-2 bg-primary text-white rounded "
                    onClick={onSave}
                  >
                    ACCEPT
                  </button>
                  <button
                    className="mr-2 px-4 py-2 bg-gray-300 text-gray-700 rounded"
                    onClick={onClose}
                  >
                    REJECT
                  </button>
                  {/* <button
              className="mr-2 px-4 py-2 bg-gray-300 text-gray-700 rounded "
              onClick={onClose}
            >
              SETTINGS
            </button> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-white p-4 rounded-lg shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] font-Glacial ">
              <button className="absolute right-7 z-50" onClick={onClose}>
                <div className="w-[30px] h-[30px] bg-[#FF914D1A] p-[8px] text-primary rounded-full">
                  <AiOutlineClose />
                </div>
              </button>
              <h2 className="text-2xl font-bold mb-2">Resumen de privacidad</h2>
              <div className="md:flex block gap-10 w-full">
                <p className="mb-2 text-sm w-5/6">
                  {data?.policyDetails?.policySummary && (
                    <div>{parse(data?.policyDetails?.policySummary)}</div>
                  )}
                </p>
                <div className=" w-1/6 flex justify-start md:flex-col gap-2 ">
                  <button
                    className="mr-2 px-4 py-2 bg-primary text-white rounded "
                    onClick={onSave}
                  >
                    ACEPTAR
                  </button>
                  <button
                    className="mr-2 px-4 py-2 bg-gray-300 text-gray-700 rounded"
                    onClick={onClose}
                  >
                    RECHAZAR
                  </button>
                  {/* <button
              className="mr-2 px-4 py-2 bg-gray-300 text-gray-700 rounded "
              onClick={onClose}
            >
              CONFIGURACIÓN
            </button> */}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CookieModal;
