import React from "react";
import Header from "../components/Header";
import Title from "../components/Title";
import DBtn from "../components/DBtn";
import ShowCookies from "../components/ShowCookies";

const AvailabilityList = () => {
  return (
    <div>
      <div className="border-b-[1px] border-[#FF914D]">
        <div className="container mx-auto w-full">
          <div className="">
            <Header />
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <ShowCookies />
      </div>
      <div className="flex container mx-auto">
        <div className="mx-auto container pt-[40px] font-bold">
          <Title title="Availability list" />
        </div>
        <div className="pt-[30px] w-full justify-end flex">
          <DBtn
            variant="outline"
            size="small"
            // onClick={(e) => console.log("button click")}
            startIcon=""
          >
            Add new availability
          </DBtn>
        </div>
      </div>

      <div>
        <div className="pt-[24px]">
          <div className="container mx-auto">
            {[...Array(3).keys()]?.map((item, index) => {
              return (
                <>
                  <div className="container mx-auto flex justify-between items-center py-[6px] bg-[#FFF4ED] border-[1px] w-full">
                    <div className="pl-[24px] text-lg text-[#FF914D]"></div>
                    <div className="pr-[24px] text-lg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M8.11068 1.19824H6.53053C2.58015 1.19824 1 2.77839 1 6.72877V11.4692C1 15.4196 2.58015 16.9998 6.53053 16.9998H11.271C15.2214 16.9998 16.8015 15.4196 16.8015 11.4692V9.88908"
                          stroke="#FF914D"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.0919 2.00437L5.8661 8.23016C5.62907 8.46718 5.39205 8.93333 5.34465 9.27306L5.00491 11.6512C4.8785 12.5124 5.48686 13.1128 6.34804 12.9943L8.72617 12.6546C9.058 12.6072 9.52415 12.3702 9.76907 12.1331L15.9949 5.90734C17.0694 4.83284 17.575 3.58452 15.9949 2.00437C14.4147 0.424216 13.1664 0.929864 12.0919 2.00437Z"
                          stroke="#FF914D"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.1992 2.89746C11.7286 4.78574 13.206 6.26318 15.1022 6.80044"
                          stroke="#FF914D"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 2xl:gap-44 xl:gap-[45px]  my-4 justify-between content-center container mx-auto">
                    {[...Array(3).keys()]?.map((item, index) => {
                      return (
                        <>
                          <div className="w-[395px] border-[1px] pt-[10px] mt-[10px]">
                            <div className="flex justify-between pt-[16px] px-[24px]">
                              <div>
                                <span>From : 14-06-2023 </span>
                              </div>
                              <div>
                                <span>To : 18-06-2023</span>
                              </div>
                            </div>
                            <div className="flex items-center pt-[24px] px-[24px]">
                              <div>
                                <DBtn
                                  variant="outline"
                                  size="small"
                                  // onClick={(e) => console.log("button click")}
                                  startIcon=""
                                >
                                  9:00am
                                </DBtn>
                              </div>
                              <span className="pl-[17px] pr-[17px]">-</span>
                              <div>
                                <DBtn
                                  variant="outline"
                                  size="small"
                                  // onClick={(e) => console.log("button click")}
                                  startIcon=""
                                >
                                  9:00am
                                </DBtn>
                              </div>
                            </div>
                            <div className="flex items-center pb-[24px] pt-[16px] px-[24px]">
                              <div>
                                <DBtn
                                  variant="outline"
                                  size="small"
                                  // onClick={(e) => console.log("button click")}
                                  startIcon=""
                                >
                                  9:00am
                                </DBtn>
                              </div>
                              <span className="pl-[17px] pr-[17px]">-</span>
                              <div>
                                <DBtn
                                  variant="outline"
                                  size="small"
                                  // onClick={(e) => console.log("button click")}
                                  startIcon=""
                                >
                                  9:00am
                                </DBtn>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div className=" sm:container mx-auto mb-[70px] pt-[32px] flex justify-end">
        <DBtn
          variant="contain"
          size=""
          // onClick={(e) => console.log("button click")}
          startIcon=""
        >
          Save
        </DBtn>
      </div>
    </div>
  );
};

export default AvailabilityList;
