import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { IoMdCheckmarkCircle } from "react-icons/io";
const PasswordStrengthIndicator = ({ password = "" }) => {
  const lang = localStorage.getItem("i18nextLng");
  const [alphanumeric, setAlphanumeric] = useState(false);
  const [specialCharacter, setSpecialCharacter] = useState(false);
  const [TwelveCharacters, setTwelveCharacters] = useState(false);

  const checkPasswordStrength = () => {
    setAlphanumeric(
      /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*()<>\\/]*$/.test(password)
        ? true
        : false
    );
    setSpecialCharacter(/[!@#$%^&*()<>\\/]/.test(password) ? true : false);
    setTwelveCharacters(password.length >= 12 ? true : false);
  };

  useEffect(() => {
    checkPasswordStrength();
  }, [password]);
  return (
    <div className="font-Comfortaa my-1 flex flex-wrap justify-start items-center gap-x-4 text-[10px] font-semibold uppercase">
      <div
        className={`flex justify-start items-center gap-1 ${
          alphanumeric ? "text-green-500" : "text-gray-400"
        }`}
      >
        <IoMdCheckmarkCircle />
        {lang === "en" || lang === "en-US" ? (
          <div>Alphanumeric</div>
        ) : (
          <div>Alfanumérico</div>
        )}
      </div>

      <div
        className={`flex justify-start items-center gap-1 ${
          specialCharacter ? "text-green-500" : "text-gray-400"
        }`}
      >
        <IoMdCheckmarkCircle />
        {lang === "en" || lang === "en-US" ? (
          <span>Special character</span>
        ) : (
          <span>caracter especial</span>
        )}
      </div>
      <div
        className={`flex justify-start items-center gap-1 ${
          TwelveCharacters ? "text-green-500" : "text-gray-400"
        }`}
      >
        <IoMdCheckmarkCircle />
        {lang === "en" || lang === "en-US" ? (
          <span>12 characters</span>
        ) : (
          <span>12 caracteres</span>
        )}
      </div>
    </div>
  );
};

const Input = ({
  type,
  placeholder,
  name,
  icon,
  value,
  setGetPassword,
  setIfValid,
  passwordIndicatorVisible = false,
  ...rest
}) => {
  // const { value } = { ...rest };

  const [passwordVisible, setPasswordVisible] = useState(false);
  const inputType = type === "password" && passwordVisible ? "text" : type;

  const [password, setPassword] = useState("");
  // console.log("password", password);
  const [valid, setValid] = useState(true);

  // const validatePassword = (password) => {
  //   const regex =
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{12,}$/;
  //   const isValid = regex.test(password);
  //   setValid(isValid);
  // };
  const validatePassword = (password) => {
    // Define your password validation criteria
    const isAlphanumeric =
      /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*()<>\\/]*$/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*()<>\\/]/.test(password);
    const isTwelveCharacters = password.length >= 12;

    // Set an error if any condition is not met
    if (!isAlphanumeric || !hasSpecialCharacter || !isTwelveCharacters) {
      return false;
    }

    return true; // Password meets all criteria
  };
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    // console.log("newPassword", newPassword);
    setPassword(newPassword);
    const isValid = validatePassword(newPassword);
    setValid(isValid);
    setIfValid(!isValid);
    // onChange(newPassword);
    setGetPassword(newPassword);
    return newPassword;
  };

  return (
    <div className={`form-control`}>
      <div className="relative flex items-center w-[270px] xl:w-[374px]">
        <input
          value={value}
          type={inputType}
          placeholder={placeholder}
          name={name}
          onChange={handlePasswordChange}
          className="w-full mx-auto h-[70px]  outline-none rounded-full ps-[104px] pe-20 font-Glacial sm:text-[20px] text-[12px] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] shadow-[#E6E6E6E5] placeholder:text-[#ABABAB] "
          {...rest}
        />
        {type === "password" && (
          <button
            type="button"
            className="absolute right-[24px] text-2xl top-[25px] text-primary cursor-pointer"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {passwordVisible ? <AiOutlineEye /> : <AiOutlineEye />}
          </button>
        )}
      </div>
      {/* {!valid && (
        <p className="text-red-500 text-xs ml-[80px]">
          Please enter correct Password
        </p>
      )} */}
      {type === "password" && passwordIndicatorVisible && (
        <PasswordStrengthIndicator password={password} />
      )}
    </div>
  );
};

export default Input;
