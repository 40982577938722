import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Title from "../components/Title";
import Dish2 from "../components/DishFav";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getFavoriteDishes } from "../services/commonService";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";
import DBtn from "../components/DBtn";

const FavoriteList = () => {
  const [favoriteList, setFavoriteList] = useState([]);
  const navigate = useNavigate();
  const lang = localStorage.getItem("i18nextLng");
  const { user } = useSelector((state) => state.auth);
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  // console.log("favoriteList", favoriteList);
  useEffect(() => {
    setFlag(true);
  }, []);

  const getDishList = async () => {
    setLoading(true);
    try {
      const resp = await getFavoriteDishes(user.id);

      if (resp?.success) {
        setFavoriteList(resp?.data);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (flag) {
      setFlag(false);
      getDishList();
    }
  }, [flag]);

  useEffect(() => {
    // reset scroll position when component updates (page changes)
    window.scrollTo(0, 0);
  }, []);

  // console.log("FavoriteList: ",favoriteList)

  return (
    <>
      <div className="container mx-auto w-full">
        <div className="border-b-[1px] border-[#FF914D] ">
          <div className="container mx-auto">
            <Header />
          </div>
        </div>

        <div className="flex sm:pt-[0px] md:items-center ">
          <div className="mx-auto md:container md:pt-[40px] pt-[10px] font-bold">
            <Title title={t("favoritePage.favoriteList")} />
          </div>
        </div>

        <div className="flex justify-between container mx-auto md:pt-[40px] md:pl-[30px]">
          {loading ? (
            <div className="flex justify-center items-center m-auto h-[50vh]">
              <Loader />
            </div>
          ) : (
            <>
              {favoriteList?.length === 0 ? (
                <div className="flex justify-center w-full">
                  <div>
                    <div>
                      <img src="../images/Data-Not-Found.SVG" alt="" />
                    </div>
                    {lang === "en" || lang === "en-US" ? (
                      <div className=" w-full">
                        <p className="text-center pt-[30px] text-red-500 mb-3 font-Comfortaa">
                          No favourites addded.
                        </p>
                        <DBtn
                          variant="contain"
                          size="small"
                          onClick={() => navigate("/")}
                        >
                          Click here to add.
                        </DBtn>
                      </div>
                    ) : (
                      <div className=" w-full">
                        <p className="text-center pt-[30px] text-red-500 mb-3 font-Comfortaa">
                          No se agregaron favoritos.
                        </p>
                        <DBtn
                          variant="contain"
                          size="small"
                          onClick={() => navigate("/")}
                        >
                          Haga clic aquí para agregar.
                        </DBtn>
                      </div>
                    )}{" "}
                  </div>
                </div>
              ) : (
                <div className="container grid sm:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
                  {favoriteList?.map((item, index) => {
                    return (
                      <Link to={`/dish-details/${item._id}`} key={index}>
                        <Dish2 dish={item} setFlag={setFlag} />
                      </Link>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FavoriteList;
