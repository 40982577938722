import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import {
  editHostListing,
  getCancellation,
  getFoodCategory,
  getFoodTime,
  uploadSingleImage,
} from "../services/commonService";
import ReactSelect from "react-select";
import { components } from "react-select";
import { FaChevronDown } from "react-icons/fa";

const AddDishDetailsModal = ({ setAddDishModal, setAddAvailabilityModal }) => {
  const { t } = useTranslation();
  const root = JSON.parse(localStorage.getItem("root"));
  const authData = root.auth;
  const userid = JSON.parse(authData).user;
  const hostid = userid?.id;
  const lang = localStorage.getItem("i18nextLng");
  const [dishLoading, setDishLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputText, setInputText] = useState("");
  const [labels, setLabels] = useState([]);
  const [categoryOptionsEn, setCategoryOptionsEn] = useState();
  const [categoryOptions, setCategoryOptions] = useState();
  const [mealTimingOptionsEn, setMealTimingOptionsEn] = useState();
  const [mealTimingOptions, setMealTimingOptions] = useState();
  const [cancellationPolicyOptionsEn, setCancellationPolicyOptionsEn] =
    useState();
  const [cancellationPolicyOptions, setCancellationPolicyOptions] = useState();
  const [dishDetails, setDishDetails] = useState({
    dishTitle: "",
    mainImage: "",
    otherImages: [],
    placeImages: [],
    foodClass: "",
    foodType: "",
    mealTiming: [],
    maxDishCapacity: "",
    dishDescription: "",
    dishPrice: "",
    maxDiningCapacity: "",
    cancellationPolicy: "",
    placeAccessibility: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDishDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors = {};

    if (!dishDetails.dishTitle) {
      newErrors.dishTitle =
        lang === "en" || lang === "en-US"
          ? "Dish Title is required."
          : "Se requiere el título del plato.";
    }

    if (!dishDetails.mainImage) {
      newErrors.mainImage =
        lang === "en" || lang === "en-US"
          ? "Main Image is required."
          : "Se requiere la imagen principal.";
    }

    if (dishDetails.otherImages.length < 2) {
      newErrors.otherImages =
        lang === "en" || lang === "en-US"
          ? "At least two food Images are required."
          : "Se requieren al menos dos imágenes de alimentos.";
    }

    if (dishDetails.placeImages.length < 2) {
      newErrors.placeImages =
        lang === "en" || lang === "en-US"
          ? "At least two Place Image is required."
          : "Se requieren al menos dos imágenes de lugar.";
    }

    if (dishDetails.mealTiming.length === 0) {
      newErrors.mealTiming =
        lang === "en" || lang === "en-US"
          ? "At least one time is required."
          : "Se requiere al menos una vez.";
    }
    if (!dishDetails.foodClass) {
      newErrors.foodClass =
        lang === "en" || lang === "en-US"
          ? "Food Class is required."
          : "Se requiere la clase de comida.";
    }

    if (!dishDetails.foodType) {
      newErrors.foodType =
        lang === "en" || lang === "en-US"
          ? "Food Type is required."
          : "Se requiere el tipo de comida.";
    }

    if (!dishDetails.maxDishCapacity) {
      newErrors.maxDishCapacity =
        lang === "en" || lang === "en-US"
          ? "Max Dish Capacity is required."
          : "Se requiere la capacidad máxima del plato.";
    }

    if (!dishDetails.dishDescription) {
      newErrors.dishDescription =
        lang === "en" || lang === "en-US"
          ? "Dish Description is required."
          : "Se requiere la descripción del plato.";
    }

    if (!dishDetails.dishPrice) {
      newErrors.dishPrice =
        lang === "en" || lang === "en-US"
          ? "Dish Price is required."
          : "Se requiere el precio del plato.";
    }

    if (!dishDetails.maxDiningCapacity) {
      newErrors.maxDiningCapacity =
        lang === "en" || lang === "en-US"
          ? "Max Dining Capacity is required."
          : "Se requiere la capacidad máxima del comedor.";
    }

    if (!dishDetails.cancellationPolicy) {
      newErrors.cancellationPolicy =
        lang === "en" || lang === "en-US"
          ? "Cancellation Policy is required."
          : "Se requiere la política de cancelación.";
    }

    if (dishDetails.placeAccessibility.length === 0) {
      newErrors.placeAccessibility =
        lang === "en" || lang === "en-US"
          ? "At least one Place Accessibility option is required."
          : "Se requiere al menos una opción de accesibilidad del lugar.";
    }

    return newErrors;
  };

  const handleBankDetails = async () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setDishLoading(true);
    try {
      const uploadedPlaceImages = [];
      for (let i = 0; i < dishDetails.placeImages.length; i++) {
        const file = dishDetails.placeImages[i]?.file;
        const formData = new FormData();
        formData.append("image", file); // Add the file to FormData

        const response = await uploadSingleImage(formData);
        if (response.success) {
          uploadedPlaceImages.push(response.data); // Store the uploaded image URL
        } else {
          console.error("Upload failed for place image:", file.name);
        }
      }

      // Update the state with uploaded place images
      // setDishDetails({
      //   ...dishDetails,
      //   placeImages: uploadedPlaceImages, // Update placeImages with URLs
      // });

      // Upload 'otherImages' one by one
      const uploadedOtherImages = [];
      for (let i = 0; i < dishDetails.otherImages.length; i++) {
        const file = dishDetails.otherImages[i]?.file;
        const formData = new FormData();
        formData.append("image", file); // Add the file to FormData

        const response = await uploadSingleImage(formData);
        if (response.success) {
          uploadedOtherImages.push(response.data); // Store the uploaded image URL
        } else {
          console.error("Upload failed for other image:", file.name);
        }
      }

      // Update the state with uploaded other images
      // setDishDetails({
      //   ...dishDetails,
      //   otherImages: uploadedOtherImages, // Update otherImages with URLs
      // });
      const payload = {
        ...dishDetails,
        foodClass: dishDetails.foodType,
        foodType: dishDetails.foodClass,
        hostId: hostid,
        otherImages: uploadedOtherImages,
        placeImages: uploadedPlaceImages,
      };

      const resp = await editHostListing(payload);
      if (resp?.success) {
        toast.success(
          lang === "en" || lang === "en-US"
            ? resp?.message?.en
            : resp?.message?.es
        );
        setAddAvailabilityModal(true);
        setAddDishModal(false);
      }
    } catch (error) {
      console.error("err:", error);
    } finally {
      setDishLoading(false);
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await uploadSingleImage(formData);
        if (response.success) {
          setDishDetails({
            ...dishDetails,
            mainImage: response.data,
          });
        } else {
        }
      } catch (err) {
        console.error("err", err);
      }
    }
  };

  const handleRemoveImage = () => {
    setDishDetails({
      ...dishDetails,
      mainImage: "",
    });
  };

  const handleFileChangeOther = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    setDishDetails((prevState) => ({
      ...prevState,
      otherImages: [...prevState.otherImages, ...newImages],
    }));
  };

  const handleRemoveImageOther = (index) => {
    setDishDetails((prevState) => ({
      ...prevState,
      otherImages: prevState.otherImages.filter((_, i) => i !== index),
    }));
  };

  const handleFileChangePlace = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    setDishDetails((prevState) => ({
      ...prevState,
      placeImages: [...prevState.placeImages, ...newImages],
    }));
  };

  const handleRemoveImagePlace = (index) => {
    setDishDetails((prevState) => ({
      ...prevState,
      placeImages: prevState.placeImages.filter((_, i) => i !== index),
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setDishDetails((prevState) => ({
      ...prevState,
      foodClass: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleMealTimingSelectChange = (selectedOptions) => {
    setDishDetails((prevState) => ({
      ...prevState,
      mealTiming: selectedOptions
        ? selectedOptions?.map((option) => option?.value)
        : [],
    }));
  };

  const handleSelectChangeFoodType = (selectedOption) => {
    setDishDetails((prevState) => ({
      ...prevState,
      foodType: selectedOption ? selectedOption.value : "", // Update with selected value
    }));
  };

  const handleSelectChangeCancellationPolicy = (selectedOption) => {
    setDishDetails((prevState) => ({
      ...prevState,
      cancellationPolicy: selectedOption ? selectedOption.value : "", // Update with selected value
    }));
  };

  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FaChevronDown style={{ color: "#FF914D", fontSize: "16px" }} />{" "}
      </components.DropdownIndicator>
    );
  };

  const fetchCategoryOptions = async () => {
    try {
      const resp = await getFoodCategory();
      if (resp?.success) {
        setCategoryOptions(
          resp?.data?.map((category) => ({
            value: category._id,
            label: category.foodType,
          }))
        );
        setCategoryOptionsEn(
          resp?.data?.map((category) => ({
            value: category._id,
            label: category.translations?.en?.foodType,
          }))
        );
      }
    } catch (err) {
      console.error("err:", err);
    }
  };

  const fetchMealTimingOptions = async () => {
    try {
      const resp = await getFoodTime();
      if (resp?.success) {
        setMealTimingOptions(
          resp?.data?.map((category) => ({
            value: category._id,
            label: category?.timingName,
          }))
        );
        setMealTimingOptionsEn(
          resp?.data?.map((category) => ({
            value: category._id,
            label: category.translations?.en?.timingName,
          }))
        );
      }
    } catch (err) {
      console.error("err:", err);
    }
  };

  const fetchCancellationPolicyOptions = async () => {
    try {
      const resp = await getCancellation();
      if (resp?.success) {
        setCancellationPolicyOptions(
          resp?.data?.map((category) => ({
            value: category._id,
            label: category?.policyDetails,
          }))
        );
        setCancellationPolicyOptionsEn(
          resp?.data?.map((category) => ({
            value: category._id,
            label: category?.translations?.en?.policyDetails,
          }))
        );
      }
    } catch (err) {
      console.error("err:", err);
    }
  };

  const handleInputChangeAcce = (e) => {
    setInputText(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      addLabel();
    }
  };
  const addLabel = () => {
    if (inputText.trim() !== "") {
      const updatedLabels = [...labels, inputText.trim()];
      setLabels(updatedLabels);
      setInputText("");
      updateDishData(updatedLabels);
    }
  };

  const updateDishData = (updatedLabels) => {
    setDishDetails((prevData) => ({
      ...prevData,
      placeAccessibility: updatedLabels,
    }));
  };

  const removeLabel = (index) => {
    const updatedLabels = [...labels];
    updatedLabels.splice(index, 1);
    setLabels(updatedLabels);
    updateDishData(updatedLabels);
  };

  useEffect(() => {
    fetchCancellationPolicyOptions();
    fetchCategoryOptions();
    fetchMealTimingOptions();
  }, []);
  // console.log("dishDetails", dishDetails);
  return (
    <div className="">
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-white my-5 max-h-[1000px] overflow-y-auto rounded-lg shadow-lg w-[90%] min-w-[310px] max-w-[800px] relative">
          <div className="p-6 text-center mt-4">
            <div className="flex justify-center">
              <img src="../images/Loginform/logo.svg" alt="" />
            </div>
            <p className="flex justify-center font-Comfortaa-Bold text-2xl text-[#111] mt-2 mb-1">
              {t("homePage.welcome")}
            </p>
            <span className="flex justify-center text-[16px] font-Glacial text-[#757575]">
              {t("profilePage.addMenu")}
            </span>
            <div className="my-5">
              <div
                className={`grid grid-cols-1 md:grid-cols-2 gap-4 ${
                  errors.dishTitle || errors.mainImage ? "mt-2" : "mt-6"
                }`}
              >
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img
                      src="../images/BankInfo/addDish/dishTitle.svg"
                      alt=""
                    />
                  </span>
                  <div>
                    <input
                      type="text"
                      name="dishTitle"
                      value={dishDetails.dishTitle}
                      onChange={handleChange}
                      placeholder={t("profilePage.enterTitle")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />

                    {errors.dishTitle && (
                      <p className="text-red-400 text-left text-base mt-[2px]">
                        {errors.dishTitle}
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/addDish/dispImg.svg" alt="" />
                  </span>
                  <div>
                    {dishDetails?.mainImage ? (
                      <div className="rounded-xl bg-white border pl-16 text-gray-900 block text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary">
                        <img
                          src={dishDetails.mainImage}
                          alt="Uploaded"
                          className="h-7 w-16 border rounded-lg"
                        />
                        <button
                          onClick={handleRemoveImage}
                          className="absolute top-0 left-28 p-1 bg-gray-500 text-white rounded-full hover:bg-gray-700"
                        >
                          <RxCross1 size={10} />
                        </button>
                      </div>
                    ) : (
                      <label className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary">
                        <span className="text-gray-500 md:-ml-[80px] sm:-ml-24">
                          {t("profilePage.uploadDisplayImage")}
                        </span>
                        <input
                          type="file"
                          name="mainImage"
                          onChange={handleFileChange}
                          accept="image/*"
                          className="hidden"
                        />
                      </label>
                    )}
                    {errors.mainImage && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.mainImage}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`grid grid-cols-1 md:grid-cols-2 gap-4 ${
                  errors.otherImages || errors.placeImages ? "mt-2" : "mt-6"
                }`}
              >
                {/* <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/addDish/dispImg.svg" alt="" />
                  </span>
                  <div>
                    <input
                      type="file"
                      name="otherImages"
                      multiple
                      onChange={handleFileChangeOther}
                      accept="image/*"
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary"
                    />
                    <div className="mt-2">
                      {dishDetails.otherImages &&
                        dishDetails.otherImages.length > 0 && (
                          <div className="flex items-center gap-2">
                            {dishDetails?.otherImages?.map((image, index) => (
                              <div key={index} className="relative">
                                <img
                                  src={image.preview}
                                  alt={`image-${index}`}
                                  className="h-7 w-16 border rounded-lg"
                                />
                                <button
                                  onClick={() => handleRemoveImageOther(index)}
                                  className="absolute top-0 right-0 p-1 bg-gray-500 text-white rounded-full hover:bg-gray-700"
                                >
                                  <RxCross1 size={10} />
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                    {errors.otherImages && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.otherImages}
                      </p>
                    )}
                  </div>
                </div> */}
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0">
                    <img src="../images/BankInfo/addDish/dispImg.svg" alt="" />
                  </span>
                  <div>
                    <input
                      type="file"
                      name="otherImages"
                      id="fileInput"
                      multiple
                      onChange={handleFileChangeOther}
                      accept="image/*"
                      className="hidden"
                    />
                    <div
                      className="cursor-pointer text-left pl-16 rounded-xl bg-white border text-gray-500 block min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary"
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                    >
                      {dishDetails.otherImages?.length > 0
                        ? `${dishDetails.otherImages.length} files selected`
                        : `${t("profilePage.uploadFoodImage")}`}
                    </div>
                    <div className="mt-2">
                      {dishDetails.otherImages &&
                        dishDetails.otherImages.length > 0 && (
                          <div className="flex items-center gap-2">
                            {dishDetails?.otherImages?.map((image, index) => (
                              <div key={index} className="relative">
                                <img
                                  src={image.preview}
                                  alt={`img-${index}`}
                                  className="h-7 w-16 border rounded-lg"
                                />
                                <button
                                  onClick={() => handleRemoveImageOther(index)}
                                  className="absolute top-0 right-0 p-1 bg-gray-500 text-white rounded-full hover:bg-gray-700"
                                >
                                  <RxCross1 size={10} />
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                    {/* Error Message */}
                    {errors.otherImages && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.otherImages}
                      </p>
                    )}
                  </div>
                </div>
                {/* <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/addDish/dispImg.svg" alt="" />
                  </span>
                  <div>
                    <input
                      type="file"
                      name="placeImages"
                      multiple
                      onChange={handleFileChangePlace}
                      accept="image/*"
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary"
                    />
                    <div className="mt-2">
                      {dishDetails?.placeImages &&
                        dishDetails?.placeImages?.length > 0 && (
                          <div className="flex items-center gap-2">
                            {dishDetails?.placeImages?.map((image, index) => (
                              <div key={index} className="relative">
                                <img
                                  src={image.preview}
                                  alt={`image-${index}`}
                                  className="h-7 w-16 border rounded-lg"
                                />
                                <button
                                  onClick={() => handleRemoveImagePlace(index)}
                                  className="absolute top-0 right-0 p-1 bg-gray-500 text-white rounded-full hover:bg-gray-700"
                                >
                                  <RxCross1 size={10} />
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                    {errors.placeImages && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.placeImages}
                      </p>
                    )}
                  </div>
                </div> */}
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0">
                    <img src="../images/BankInfo/addDish/dispImg.svg" alt="" />
                  </span>
                  <div className="">
                    <div className="">
                      <div
                        className="cursor-pointer text-left pl-16 rounded-xl bg-white border text-gray-500 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary"
                        onClick={() =>
                          document.getElementById("placeFileInput").click()
                        }
                      >
                        {dishDetails?.placeImages?.length > 0
                          ? `${dishDetails.placeImages.length} files selected`
                          : `${t("profilePage.uploadPlaceImage")}`}
                      </div>
                    </div>
                    <input
                      type="file"
                      name="placeImages"
                      id="placeFileInput"
                      multiple
                      onChange={handleFileChangePlace}
                      accept="image/*"
                      className="hidden"
                    />
                    {/* Selected Images Preview */}
                    <div className="mt-2">
                      {dishDetails?.placeImages &&
                        dishDetails.placeImages.length > 0 && (
                          <div className="flex items-center gap-2">
                            {dishDetails.placeImages.map((image, index) => (
                              <div key={index} className="relative">
                                <img
                                  src={image.preview}
                                  alt={`img-${index}`}
                                  className="h-7 w-16 border rounded-lg"
                                />
                                <button
                                  onClick={() => handleRemoveImagePlace(index)}
                                  className="absolute top-0 right-0 p-1 bg-gray-500 text-white rounded-full hover:bg-gray-700"
                                >
                                  <RxCross1 size={10} />
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                    {/* Error Message */}
                    {errors.placeImages && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.placeImages}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`grid grid-cols-1 md:grid-cols-2 gap-4 ${
                  errors.foodClass || errors.foodType ? "mt-2" : "mt-6"
                }`}
              >
                <div className="relative grid pt-[0px] font-Glacial rounded-lg">
                  <span className="absolute h-[50px] inline-flex items-center px-3 z-10 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/addDish/dishCat.svg" alt="" />
                  </span>
                  <div>
                    <ReactSelect
                      isClearable
                      name="foodClass"
                      value={
                        dishDetails.foodClass
                          ? (lang === "en" || lang === "en-US"
                              ? categoryOptionsEn
                              : categoryOptions
                            ).find(
                              (option) => option.value === dishDetails.foodClass
                            ) || null
                          : null
                      }
                      onChange={handleSelectChange}
                      options={
                        lang === "en" || lang === "en-US"
                          ? categoryOptionsEn
                          : categoryOptions
                      }
                      components={{
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          minHeight: "50px",
                          borderColor: state.isFocused ? "#F9E3D5" : "#d1d5db",
                          borderWidth: "1px",
                          borderRadius: "12px",
                          outline: "none",
                          boxShadow: state.isFocused
                            ? "0 0 0 2px #FF914D"
                            : provided.boxShadow,
                          "&:hover": { borderColor: "#FF914D" },
                        }),
                        input: (provided) => ({
                          ...provided,
                          caretColor: "transparent",
                          color: "transparent",
                          outline: "none",
                          boxShadow: "none",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          height: "50px",
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "60px",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: "9999",
                          position: "absolute",
                          top: "100%",
                          left: "0",
                          width: "100%",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          borderRadius: "4px",
                          backgroundColor: state.isSelected
                            ? "#FF914D"
                            : state.isFocused
                            ? "#FBD2BA"
                            : "#FFFFFF",
                          color: "#000000",
                          padding: "5px",
                          zIndex: "999",
                        }),
                        DropdownIndicator: (provided) => ({
                          ...provided,
                          padding: "0",
                          marginRight: "0",
                        }),
                      }}
                      placeholder={t("profilePage.selectDishCategory")}
                      className="rounded-xl text-lg"
                    />
                    {errors.foodClass && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.foodClass}
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative grid pt-[0px] font-Glacial rounded-lg">
                  <span className="absolute h-[50px] inline-flex items-center px-3 z-10 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/addDish/foodType.svg" alt="" />
                  </span>
                  <div>
                    <ReactSelect
                      isClearable
                      name="foodType"
                      value={
                        dishDetails.foodType
                          ? {
                              label: dishDetails.foodType,
                              value: dishDetails.foodType,
                            }
                          : null
                      }
                      onChange={handleSelectChangeFoodType}
                      options={
                        lang === "en" || lang === "en-US"
                          ? [
                              { value: "Vegetariano", label: "Vegetarian" },
                              {
                                value: "No Vegetariano",
                                label: "Non-Vegetarian",
                              },
                              { value: "Vegano", label: "Vegan" },
                            ]
                          : [
                              { value: "Vegetariano", label: "Vegetarian" },
                              {
                                value: "No Vegetariano",
                                label: "Non-Vegetarian",
                              },
                              { value: "Vegano", label: "Vegano" },
                            ]
                      }
                      components={{
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          minHeight: "50px",
                          borderColor: state.isFocused ? "#FF914D" : "#d1d5db",
                          borderWidth: "1px",
                          borderRadius: "12px",
                          outline: "none",
                          boxShadow: state.isFocused
                            ? "0 0 0 2px #FF914D"
                            : provided.boxShadow,
                          "&:hover": { borderColor: "#FF914D" },
                        }),
                        input: (provided) => ({
                          ...provided,
                          caretColor: "transparent",
                          color: "transparent",
                          outline: "none",
                          boxShadow: "none",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          height: "50px",
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "60px",
                        }),
                        menu: (provided) => ({
                          ...provided,
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          borderRadius: "4px",
                          backgroundColor: state.isSelected
                            ? "#FF914D"
                            : state.isFocused
                            ? "#FBD2BA"
                            : "#FFFFFF",
                          color: "#000000",
                          padding: "5px",
                        }),
                        DropdownIndicator: (provided) => ({
                          ...provided,
                          padding: "0",
                          marginRight: "0",
                        }),
                      }}
                      placeholder={t("profilePage.selectFoodType")}
                      className="rounded-xl text-lg"
                    />
                    {errors.foodType && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.foodType}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`grid grid-cols-1 md:grid-cols-2 gap-4 ${
                  errors.mealTiming || errors.maxDishCapacity ? "mt-2" : "mt-6"
                }`}
              >
                <div className="relative grid pt-[0px] font-Glacial rounded-lg">
                  <span className="absolute h-[50px] inline-flex items-center px-3 z-10 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/addDish/foodType.svg" alt="" />
                  </span>
                  <div>
                    <ReactSelect
                      isMulti
                      name="mealTiming"
                      value={
                        dishDetails?.mealTiming?.length > 0
                          ? (lang === "en" || lang === "en-US"
                              ? mealTimingOptionsEn
                              : mealTimingOptions
                            )?.filter((option) =>
                              dishDetails?.mealTiming?.includes(option.value)
                            )
                          : []
                      }
                      onChange={handleMealTimingSelectChange}
                      options={
                        lang === "en" || lang === "en-US"
                          ? mealTimingOptionsEn
                          : mealTimingOptions
                      }
                      components={{
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          minHeight: "50px",
                          borderColor: state.isFocused ? "#FF914D" : "#d1d5db",
                          borderWidth: "1px",
                          borderRadius: "12px",
                          outline: "none",
                          boxShadow: state.isFocused
                            ? "0 0 0 2px #FF914D"
                            : provided.boxShadow,
                          "&:hover": { borderColor: "#FF914D" },
                        }),
                        input: (provided) => ({
                          ...provided,
                          caretColor: "transparent",
                          color: "transparent",
                          outline: "none",
                          boxShadow: "none",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          height: "50px",
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "60px",
                        }),
                        menu: (provided) => ({
                          ...provided,
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          borderRadius: "4px",
                          backgroundColor: state.isSelected
                            ? "#FF914D"
                            : state.isFocused
                            ? "#FBD2BA"
                            : "#FFFFFF",
                          color: "#000000",
                          padding: "5px",
                        }),
                        DropdownIndicator: (provided) => ({
                          ...provided,
                          padding: "0",
                          marginRight: "0",
                        }),
                        multiValue: (provided) => ({
                          ...provided,
                          backgroundColor: "#FF914D",
                          color: "#FFFFFF",
                          borderRadius: "6px",
                          padding: "",
                        }),
                        multiValueLabel: (provided) => ({
                          ...provided,
                          color: "#FFFFFF",
                        }),
                        multiValueRemove: (provided) => ({
                          ...provided,
                          color: "#FFFFFF",
                          ":hover": {
                            borderRadius: "6px",
                            backgroundColor: "#FF914D",
                          },
                        }),
                      }}
                      placeholder={t("profilePage.selectFoodTime")}
                      className="rounded-xl text-lg"
                    />
                    {errors.mealTiming && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.mealTiming}
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img
                      src="../images/BankInfo/addDish/noOfServe.svg"
                      alt=""
                    />
                  </span>
                  <div>
                    <input
                      type="text"
                      name="maxDishCapacity"
                      value={dishDetails.maxDishCapacity}
                      onChange={handleChange}
                      placeholder={t("profilePage.noOfServings")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />{" "}
                    {errors.maxDishCapacity && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.maxDishCapacity}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`grid grid-cols-1 md:grid-cols-2 gap-4 ${
                  errors.dishDescription || errors.dishPrice ? "mt-2" : "mt-6"
                }
  `}
              >
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/addDish/dishDesc.svg" alt="" />
                  </span>
                  <div>
                    <input
                      type="text"
                      name="dishDescription"
                      value={dishDetails.dishDescription}
                      onChange={handleChange}
                      placeholder={t("profilePage.enterDishDescription")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />{" "}
                    {errors.dishDescription && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.dishDescription}
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img
                      src="../images/BankInfo/addDish/dishPrice.svg"
                      alt=""
                    />
                  </span>
                  <div>
                    <input
                      type="text"
                      name="dishPrice"
                      value={dishDetails.dishPrice}
                      onChange={handleChange}
                      placeholder={t("profilePage.enterDishPriceField")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />{" "}
                    {errors.dishPrice && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.dishPrice}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`grid grid-cols-1 md:grid-cols-2 gap-4 ${
                  errors.maxDiningCapacity || errors.cancellationPolicy
                    ? "mt-2"
                    : "mt-6"
                }
  `}
              >
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/addDish/guestCap.svg" alt="" />
                  </span>
                  <div>
                    <input
                      type="text"
                      name="maxDiningCapacity"
                      value={dishDetails.maxDiningCapacity}
                      onChange={handleChange}
                      placeholder={t("profilePage.enterGuestCapacityField")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                    />{" "}
                    {errors.maxDiningCapacity && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.maxDiningCapacity}
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative grid pt-[0px] font-Glacial rounded-lg">
                  <span className="absolute h-[50px] inline-flex items-center px-3 z-10 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/addDish/cancel.svg" alt="" />
                  </span>
                  <div>
                    <ReactSelect
                      isClearable
                      name="cancellationPolicy"
                      value={
                        dishDetails.cancellationPolicy
                          ? (lang === "en" || lang === "en-US"
                              ? cancellationPolicyOptionsEn
                              : cancellationPolicyOptions
                            ).find(
                              (option) =>
                                option.value === dishDetails.cancellationPolicy
                            ) || null
                          : null
                      }
                      onChange={handleSelectChangeCancellationPolicy}
                      options={
                        lang === "en" || lang === "en-US"
                          ? cancellationPolicyOptionsEn
                          : cancellationPolicyOptions
                      }
                      components={{
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          minHeight: "50px",
                          borderColor: state.isFocused ? "#FF914D" : "#d1d5db",
                          borderWidth: "1px",
                          borderRadius: "12px",
                          outline: "none",
                          boxShadow: state.isFocused
                            ? "0 0 0 2px #FF914D"
                            : provided.boxShadow,
                          "&:hover": { borderColor: "#FF914D" },
                        }),
                        input: (provided) => ({
                          ...provided,
                          caretColor: "transparent",
                          color: "transparent",
                          outline: "none",
                          boxShadow: "none",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          height: "50px",
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "60px",
                        }),
                        menu: (provided) => ({
                          ...provided,
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          borderRadius: "4px",
                          backgroundColor: state.isSelected
                            ? "#FF914D"
                            : state.isFocused
                            ? "#FBD2BA"
                            : "#FFFFFF",
                          color: "#000000",
                          padding: "5px",
                        }),
                        DropdownIndicator: (provided) => ({
                          ...provided,
                          padding: "0",
                          marginRight: "0",
                        }),
                      }}
                      placeholder={t("profilePage.selectCancellation")}
                      className="rounded-xl text-lg placeholder:text-left"
                    />
                    {errors.cancellationPolicy && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.cancellationPolicy}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`grid grid-cols-1 gap-4 ${
                  errors.placeAccessibility ? "mt-2" : "mt-6"
                }`}
              >
                <div className="relative grid pt-[0px] font-Glacial">
                  <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                    <img src="../images/BankInfo/addDish/acc.svg" alt="" />
                  </span>
                  <div className="mb-4">
                    <input
                      type="text"
                      placeholder={t("profilePage.mentionAccessibility")}
                      className="rounded-xl bg-white border pl-16 text-gray-900 block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                      value={inputText}
                      onChange={handleInputChangeAcce}
                      onKeyPress={handleInputKeyPress}
                    />
                    {errors.placeAccessibility && (
                      <p className="text-red-400 text-left text-base mt-1">
                        {errors.placeAccessibility}
                      </p>
                    )}
                  </div>
                  <div className="flex flex-wrap">
                    {labels.map((label, index) => (
                      <div
                        key={index}
                        className="relative bg-primary text-white font-Glacial rounded-md pl-4 pr-10 py-1 mr-2 mb-2"
                      >
                        {label}
                        <button
                          className="absolute top-1 right-2 mt-1 mr-1 focus:outline-none"
                          onClick={() => removeLabel(index)}
                        >
                          <RxCross1 />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="bottom-6 right-6 flex justify-end gap-4 ">
              <button
                onClick={handleBankDetails}
                disabled={dishLoading}
                className={`bg-primary font-Comfortaa-Medium text-white py-2 px-4 rounded-full font-medium text-sm hover:bg-primary-dark focus:outline-none ${
                  dishLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {dishLoading
                  ? lang === "en" || lang === "en-US"
                    ? "Saving..."
                    : "Ahorro..."
                  : lang === "en" || lang === "en-US"
                  ? "Save and Next"
                  : "Guardar y Siguiente"}
              </button>
              <button
                className="bg-gray-300 font-Comfortaa-Medium text-gray-700 py-2 px-4 rounded-full font-medium text-sm hover:bg-gray-400 focusbaseutline-none"
                onClick={() => {
                  setAddDishModal(false);
                  setAddAvailabilityModal(true);
                }}
              >
                {lang === "en" || lang === "en-US" ? "Skip" : "Saltar"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDishDetailsModal;
