import React from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { MdStarRate } from "react-icons/md";
import EmptyHeart from "../svg/EmptyHeart";
import TruncateWords from "./TruncateWords";

const Dish2 = ({ dish }) => {
  const lang = localStorage.getItem("i18nextLng");
  let address = (dish?.hostId?.address?.addressLine1 || "").concat(
    " ",
    dish?.hostId?.address?.addressLine2 || ""
  );
  let addressSpain = (
    dish?.hostId?.translations?.en?.address?.addressLine1 || ""
  ).concat(" ", dish?.hostId?.translations?.en?.address?.addressLine2 || "");
  return (
    <>
      <div className="w-[300px] md:w-[430px] h-[250px] xl:m-5 mt-2 p-4 relative  shadow-[0_3px_10px_rgb(0,0,0,0.2)] first-letter rounded-[10px] shadow-[#E6E6E6E5] ">
        <div className="flex justify-between">
          <div>
            <p className="sm:w-[300px] capitalize h-[60px] text-title font-Glacial text-2xl text-[#111111] ">
              <TruncateWords
                maxWidth="350px"
                maxLines={2}
                text={
                  lang === "en" || lang === "en-US"
                    ? dish?.translations?.en?.dishTitle
                      ? dish?.translations?.en?.dishTitle
                      : dish?.dishTitle
                    : dish?.dishTitle
                }
              />
            </p>
          </div>
          <div
            onClick={(e) => e.preventDefault()}
            className="w-[32px] h-[32px] bg-[#FF914D1A] p-[9px] text-primary rounded-full"
          >
            <EmptyHeart
              status={1}
              favoriteId={dish?._id}
              className="text-sm cursor-pointer"
              // onClick={() => console.log()}
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="w-2/3">
            <div className="flex items-center  gap-x-6 ">
              {dish?.address?.city && (
                <div className="flex items-center gap-x-1">
                  <HiOutlineLocationMarker size={20} className="text-primary" />
                  <p className="font-Glacial text-lg text-[#474747] line-clamp-1 lg:line-clamp-2">
                    {lang === "en" || lang === "en-US" ? (
                      <>
                        {dish?.hostId?.translations?.en?.address?.city ===
                        "" ? (
                          <>
                            {" "}
                            {dish?.hostId?.address?.city},{" "}
                            {dish?.hostId?.address?.country}
                          </>
                        ) : (
                          <>
                            {dish?.hostId?.translations?.en?.address?.city},{" "}
                            {dish?.hostId?.translations?.en?.address?.country}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {dish?.hostId?.address?.city},{" "}
                        {dish?.hostId?.address?.country}
                      </>
                    )}
                  </p>
                </div>
              )}

              <span className="flex items-center justify-center text-primary text-[18px] font-Comfortaa-SemiBold">
                ${dish?.dishPrice}
              </span>
            </div>
            <div className="flex items-center gap-x-1 pt-1">
              <MdStarRate className="text-primary " />
              <p className="text-[16px] text-[#757575] font-Glacial">
                {dish?.hostId?.avgHostRatings}
              </p>
            </div>
            {/* <div className="flex justify-between pt-[12px]">
              <div className="font-Glacial">
                <FaRegCalendarAlt className="text-primary" />
                <span className="text-[12px] text-[#ABABAB] ">
                  9-12-2023 to 11-12-2023
                </span>
              </div>
              <div className="w-[0.5px] bg-[#ABABAB]"></div>
              <div className="font-Glacial">
                <IoMdPerson className="text-primary " />
                <span className="text-[12px] text-[#ABABAB] ">
                  1 Guests, 1 Adult
                </span>
              </div>
              <div className="w-[0.5px] bg-[#ABABAB]"></div>

              <div className="font-Glacial">
                <GiMeal className="text-primary " />
                <span className="text-[12px] text-[#ABABAB] ">2 Dishes</span>
              </div>
            </div> */}
            <p className=" text-base font-Glacial text-[#ABABAB] py-[10px] ">
              <TruncateWords
                maxWidth="250px"
                maxLines={1}
                text={
                  lang === "en" || lang === "en-US" ? address : addressSpain
                }
              />
            </p>
          </div>

          <div className="w-1/3 flex items-center ">
            <img
              className="w-[120px] h-[120px] object-cover rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.1)] first-letter  shadow-[#FF914D33]"
              src={dish?.mainImage}
              alt=""
            />
          </div>
        </div>
        <div className="flex items-center  gap-x-2 pt-4">
          {dish?.hostId?.firstName ? (
            <>
              <img
                className="w-6 h-6 object-cover rounded-full"
                src={dish?.hostId?.profileImage}
                alt={dish?.hostId?.firstName}
              />
            </>
          ) : (
            <></>
          )}
          <p className="font-Glacial text-sm text-[#BABABA] line-clamp-1 ">
            {lang === "en" || lang === "en-US" ? (
              <>
                {dish?.hostId?.firstName} {dish?.hostId?.lastName}
              </>
            ) : (
              <>
                {dish?.hostId?.translations?.en?.firstName}{" "}
                {dish?.hostId?.translations?.en?.lastName}
              </>
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default Dish2;
