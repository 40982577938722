import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../components/Header";
import Title from "../components/Title";
import Dish from "../svg/Dish";
import DishPrice from "../svg/DishPrice";
import GuestCapacity from "../svg/GuestCapacity";
import { getDishDetails, updateHostDishById } from "../services/dishService";
import { IoCloseOutline, IoCloudUploadOutline } from "react-icons/io5";
import Loader from "../components/Loader";
import Dropdown from "../components/Dropdown";
import FoodCategory from "../svg/FoodCategory";
import FourSqure from "../svg/FourSqure";
import Select from "react-select";
import Cancelation from "../svg/Cancelation";
import { RxCross1 } from "react-icons/rx";
import DBtn from "../components/DBtn";
import {
  getCancellation,
  getFoodCategory,
  getFoodTime,
  uploadSingleImage,
} from "../services/commonService";
import { toast } from "react-toastify";
import { AiOutlineDown } from "react-icons/ai";
import { useTranslation } from "react-i18next";

// import { useSelector } from "react-redux";

const DishFormEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const root = localStorage.getItem("root");
  const lang = localStorage.getItem("i18nextLng");
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const [dishDetail, setDishDetail] = useState({});
  const [labels, setLabels] = useState([]);
  const [flag, setFlag] = useState(false);
  const [hoveredIndexOther, setHoveredIndexOther] = useState(null);
  const [hoveredIndexPlace, setHoveredIndexPlace] = useState(null);
  const [configs, setConfigs] = useState({
    categoryEn: [],
    timeEn: [],
    cancelEn: [],
    category: [],
    time: [],
    cancel: [],
  });

  useEffect(() => {
    if (!root) {
      navigate("/");
    }
  }, [root]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loading]);

  useEffect(() => {
    setFlag(true);
  }, []);

  const handleInputChangeAcce = (e) => {
    setInputText(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      addLabel();
    }
  };

  const addLabel = () => {
    if (inputText.trim() !== "") {
      const updatedLabels = [...labels, inputText.trim()];
      setLabels(updatedLabels);
      setInputText("");
      updateDishData(updatedLabels);
    }
  };

  const removeLabel = (index) => {
    const updatedLabels = [...labels];
    updatedLabels.splice(index, 1);
    setLabels(updatedLabels);
    updateDishData(updatedLabels);
  };

  const updateDishData = (updatedLabels) => {
    setDishDetail((prevData) => ({
      ...prevData,
      placeAccessibility: updatedLabels,
    }));
  };

  const getDishDetailsData = async () => {
    try {
      setLoading(true);
      const resp = await getDishDetails(id);
      if (resp?.success) {
        setLabels(resp?.data?.placeAccessibility);
        setDishDetail({
          ...resp?.data,
          foodType: {
            ...resp?.data?.foodType,
            value: resp?.data?.foodType?._id,
            // label: resp?.data?.foodType?.foodType,
          },
          cancellationPolicyDetails: {
            ...resp?.data?.cancellationPolicyDetails,
            value: resp?.data?.cancellationPolicyDetails?._id,
          },
        });
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoading(false);
    }
  };

  const getFoodData = async () => {
    try {
      let foodCategory;
      let foodTime;
      let cancellation;

      let foodData;
      let timeData;
      let foodCancellation;
      let foodDataEn;
      let timeDataEn;
      let foodCancellationEn;

      foodCategory = await getFoodCategory();

      foodDataEn = foodCategory?.data?.map((item) => {
        return {
          value: item?._id,
          label: item?.translations?.en?.foodType,
        };
      });
      // console.log("foodDataen", foodDataEn);
      foodData = foodCategory?.data?.map((item) => {
        return {
          value: item?._id,
          label: item?.foodType,
        };
      });

      foodTime = await getFoodTime();
      timeDataEn = foodTime?.data?.map((item) => {
        return {
          value: item?._id,
          label: item?.translations?.en?.timingName,
        };
      });
      // console.log("timeDataen", timeDataEn);
      timeData = foodTime?.data?.map((item) => {
        return {
          value: item?._id,
          label: item?.timingName,
        };
      });

      cancellation = await getCancellation();

      foodCancellationEn = cancellation?.data?.map((item) => {
        return {
          value: item?._id,
          label: item?.translations?.en?.policyDetails,
        };
      });
      // console.log("foodCancellationEn", foodCancellationEn);

      foodCancellation = cancellation?.data?.map((item) => {
        return {
          value: item?._id,
          label: item?.policyDetails,
        };
      });

      setConfigs({
        ...configs,
        categoryEn: foodDataEn,
        timeEn: timeDataEn,
        cancelEn: foodCancellationEn,
        category: foodData,
        time: timeData,
        cancel: foodCancellation,
      });
    } catch (err) {
      console.error("err", err);
    } finally {
    }
  };

  useEffect(() => {
    getDishDetailsData();
    getFoodData();
    setFlag(false);
  }, [flag]);

  const handleRemoveOtherImage = (index) => {
    const updatedImages = [...dishDetail.otherImages];
    updatedImages.splice(index, 1);
    setDishDetail({ ...dishDetail, otherImages: updatedImages });
  };

  const handleRemovePlaceImage = (index) => {
    const updatedImages = [...dishDetail.placeImages];
    updatedImages.splice(index, 1);
    setDishDetail({ ...dishDetail, placeImages: updatedImages });
  };

  const handleInputChange1 = async (e) => {
    const { name, value, files } = e.target;
    if (files?.length > 0) {
      const image = files[files.length - 1];

      const imageData = new FormData();
      imageData.append("image", image);
      try {
        const resp = await uploadSingleImage(imageData);

        if (resp?.success) {
          setDishDetail({
            ...dishDetail,
            [name]: resp?.data,
          });
        } else {
          setDishDetail({
            ...dishDetail,
            [name]: "",
          });
        }
      } catch (err) {
        console.error("err", err);
      } finally {
      }
    } else if (value) {
      setDishDetail({
        ...dishDetail,
        [name]: value,
      });
    }
  };

  const handleInputChange = async (e) => {
    const { name, value, files } = e.target;

    if (files?.length > 0) {
      const newImages = Array.from(files);

      const imagePreviews = newImages.map((image) =>
        URL.createObjectURL(image)
      );

      setDishDetail((prevData) => ({
        ...prevData,
        [`${name}Previews`]: [
          ...(prevData[`${name}Previews`] || []),
          ...imagePreviews,
        ],
      }));

      const uploadedImages = await Promise.all(
        newImages.map(async (image) => {
          const imageData = new FormData();
          imageData.append("image", image);

          try {
            const resp = await uploadSingleImage(imageData);

            if (resp?.success) {
              return resp.data;
            } else {
              return "";
            }
          } catch (err) {
            console.error("err", err);
            return "";
          }
        })
      );

      setDishDetail((prevData) => ({
        ...prevData,
        [name]: [...(prevData[name] || []), ...uploadedImages],
      }));
    } else if (value) {
      setDishDetail({
        ...dishDetail,
        [name]: value,
      });
    }
  };

  const handleImageDelete = () => {
    setDishDetail((prevState) => ({
      ...prevState,
      mainImage: null,
    }));
  };

  const handleMouseEnterOther = (index) => {
    setHoveredIndexOther(index);
  };

  const handleMouseLeaveOther = () => {
    setHoveredIndexOther(null);
  };

  const handleMouseEnterPlace = (index) => {
    setHoveredIndexPlace(index);
  };

  const handleMouseLeavePlace = () => {
    setHoveredIndexPlace(null);
  };

  const CustomDropdownIndicator = (props) => (
    <div {...props} style={{ marginRight: "24px", marginTop: "10px" }}>
      <AiOutlineDown color="#FF914D" size={16} />
    </div>
  );

  const handleUpdate = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    // console.log("Before dishDetail:", dishDetail);
    try {
      let payload = {
        hostId: dishDetail?.hostId?._id,
        dishTitle: dishDetail?.dishTitle,
        dishPrice: dishDetail?.dishPrice,
        dishDescription: dishDetail?.dishDescription,
        maxDishCapacity: dishDetail?.maxDishCapacity,
        maxDiningCapacity: dishDetail?.maxDishCapacity,
        mainImage: dishDetail?.mainImage,
        otherImages: dishDetail?.otherImages,
        placeImages: dishDetail?.placeImages,
        placeAccessibility: dishDetail?.placeAccessibility,
        cancellationPolicy: dishDetail?.cancellationPolicyDetails?.value,
        mealTiming: dishDetail?.mealTiming?.map((timing) =>
          timing._id ? timing._id : timing.value
        ),
        foodClass: dishDetail?.foodClass,
        foodType: dishDetail?.foodType?.value,
        translations: {
          en: {
            foodClass: dishDetail?.translations?.en?.foodClass,
            dishTitle: dishDetail?.translations?.en?.dishTitle,
            dishDescription: dishDetail?.translations?.en?.dishDescription,
            placeAccessibility:
              dishDetail?.translations?.en?.placeAccessibility,
          },
        },
      };
      // console.log("payload: ", payload);
      const resp = await updateHostDishById(id, payload);
      // console.log("resp: ", resp);
      if (resp?.success) {
        toast.success(
          lang === "en" || lang === "en-US"
            ? resp?.message?.en
            : resp?.message?.es
        );
        navigate(`/dish-details/${id}`);
      }
    } catch (err) {
      console.error("error in update: ", err);
    } finally {
      setBtnLoading(false);
    }
  };

  const mealTimingArray = dishDetail?.mealTiming?.map((item) => ({
    value: item._id,
    label: item.timingName,
  }));

  const mealTimingArrayEng = dishDetail?.mealTiming?.map((item) => ({
    value: item._id,
    label: item?.translations?.en?.timingName,
  }));
  // console.log(
  //   "🚀 ~ mealTimingArrayEng ~ mealTimingArrayEng:",
  //   mealTimingArrayEng
  // );
  // console.log("dis detatils", dishDetail);
  return (
    <div className="container mx-auto w-full">
      <Header />

      {loading ? (
        <div className="flex justify-center items-center m-auto h-[50vh]">
          <Loader />
        </div>
      ) : (
        <>
          <div className=" ">
            <Title title={t("dishDetail.editDish")} />
            {dishDetail?.dishTitle && (
              <div className="font-Glacial">
                <div className="pt-4 container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
                  {/* dish title */}
                  <div className=" border-[1px] border-gray-300 rounded-2xl px-[16px]">
                    <p className="text-lg font-Glacial text-[#111] pt-[16px]">
                      {t("addNewDish.dishTitle")}
                      <span className="text-red-500">*</span>
                    </p>
                    <p className="flex">
                      <span
                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                      ></span>
                    </p>
                    {/* spanish */}
                    <div className="relative flex pt-[21px] font-Glacial">
                      <span className="absolute inline-flex items-center h-[50px]  px-3 border-r text-sm text-gray-900 bg-transparent   rounded-e-0 border-gray-300 rounded-s-xl ">
                        <Dish />
                      </span>

                      <input
                        type="text"
                        name="dishTitle"
                        value={dishDetail?.dishTitle}
                        onChange={(e) =>
                          setDishDetail({
                            ...dishDetail,
                            dishTitle: e.target.value,
                          })
                        }
                        placeholder="Enter Your Dishname"
                        className=" rounded-xl bg-white border text-gray-900 pl-16  block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-2 focus:ring-primary focus:border-none "
                      />
                    </div>

                    {/* english  */}
                    <>
                      <div className="pt-[10px] font-Glacial pb-2">
                        <span className="text-lg">{t("english")}</span>
                      </div>
                      <div className="relative flex pt-[0px] font-Glacial pb-3">
                        <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0  ">
                          <Dish />
                        </span>
                        <input
                          type="text"
                          name="enDishTitle"
                          value={dishDetail?.translations?.en?.dishTitle}
                          onChange={(e) =>
                            setDishDetail({
                              ...dishDetail,
                              translations: {
                                ...dishDetail?.translations,
                                en: {
                                  ...dishDetail?.translations?.en,
                                  dishTitle: e.target.value,
                                },
                              },
                            })
                          }
                          placeholder="Enter Your Dishname"
                          className="rounded-xl bg-white border pl-16 text-gray-900  block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                        />
                      </div>
                    </>
                  </div>
                  {/* dish price */}
                  <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] pb-[24px]">
                    <p className="text-lg font-Glacial text-[#111] pt-[16px]">
                      {t("addNewDish.dishPrice")}
                      <span className="text-red-500">*</span>
                    </p>
                    <p className="flex">
                      <span
                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                      ></span>
                    </p>
                    <div className="pt-[24px]">
                      <div className="relative flex  font-Glacial">
                        <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0 border-gray-300 rounded-s-xl ">
                          <DishPrice />
                        </span>

                        <input
                          name="dishPrice"
                          type="number"
                          value={dishDetail?.dishPrice}
                          placeholder="Enter Your DishPrice"
                          onChange={(e) =>
                            setDishDetail({
                              ...dishDetail,
                              dishPrice: e.target.value,
                            })
                          }
                          className=" rounded-xl pl-16 bg-white border text-gray-900  block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                        />
                      </div>
                    </div>
                  </div>
                  {/* guestcapacity */}
                  <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] pb-[24px]">
                    <p className="text-lg font-Glacial text-[#111] pt-[16px]">
                      {t("addNewDish.guestCapacity")}
                      <span className="text-red-500">*</span>
                    </p>
                    <p className="flex">
                      <span
                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                      ></span>
                    </p>
                    <div className="pt-[24px]">
                      <div className="relative flex  font-Glacial">
                        <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0 border-gray-300 rounded-s-xl ">
                          <GuestCapacity />
                        </span>
                        <input
                          name="maxDishCapacity"
                          type="number"
                          value={dishDetail?.maxDishCapacity}
                          onWheel={(e) => {
                            e.target.blur();
                            e.stopPropagation();
                            setTimeout(() => {
                              e.target.focus();
                            }, 0);
                          }}
                          placeholder="Enter Your Guest Capacity"
                          onChange={(e) =>
                            setDishDetail({
                              ...dishDetail,
                              maxDishCapacity: e.target.value,
                            })
                          }
                          className="pl-16 rounded-xl bg-white border text-gray-900  block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" pt-4 container mx-auto grid grid-cols-1 xl:grid-cols-3 gap-4">
                  {/* display image */}
                  <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
                    <p className="text-lg font-Glacial text-[#111] ">
                      {t("addNewDish.displayImg")}
                      <span className="text-red-500">*</span>
                    </p>
                    <p className="flex">
                      <span
                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                      ></span>
                    </p>
                    <div className="flex  font-Glacial mt-3 ">
                      <label className="relative sm:w-[500px] border rounded-lg h-40 ">
                        {dishDetail?.mainImage ? (
                          <div className="w-[420px] justify-start items-center gap-2 rounded-lg rounded-e-xl bg-transparent  text-gray-900  min-w-0 text-lg border-gray-300 focus:ring-0 focus:border-gray-300">
                            <img
                              className="rounded-xl object-contain  ml-32 w-[180px] h-[120px] border mt-4 p-4 border-gray-300"
                              src={dishDetail?.mainImage}
                              alt="mainImage"
                              width={60}
                              height={60}
                            />
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                handleImageDelete();
                              }}
                              className="absolute top-2 right-2 bg-white text-primary rounded-full w-8 h-8 flex items-center justify-center opacity-0 hover:opacity-100 hover:bg-gray-200"
                            >
                              <IoCloseOutline size={24} />
                            </button>
                          </div>
                        ) : (
                          <span className="rounded-none rounded-e-xl items-center justify-center bg-white  text-gray-500  min-w-0 w-full  border-gray-300 ">
                            <div className="flex items-center gap-2 ">
                              <div className="flex rounded-lg  flex-col items-center justify-center text-center mt-10 ml-32 p-4  ">
                                <IoCloudUploadOutline size={40} />
                                {t("addNewDish.uploadMainImg")}
                              </div>
                            </div>
                          </span>
                        )}

                        <input
                          name="mainImage"
                          type="file"
                          onChange={handleInputChange1}
                          className="absolute inset-0 opacity-0 h-full cursor-pointer rounded-none rounded-e-xl bg-white border text-gray-900 block flex-1 min-w-0 w-[300px] text-lg border-gray-300 p-2.5 focus:ring-0 focus:border-gray-300"
                        />
                      </label>
                    </div>

                    {/* </label> */}
                  </div>
                  {/* upload food image */}
                  <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
                    <p className="text-lg font-Glacial text-[#111] ">
                      {t("addNewDish.foodImgs")}
                      <span className="text-red-500">*</span>
                    </p>
                    <p className="flex">
                      <span
                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                      ></span>
                    </p>
                    <label className="relative  mt-3 w-full h-40 rounded-xl flex flex-col items-start  justify-center cursor-pointer bg-white border">
                      {dishDetail?.otherImages?.length > 0 && (
                        <div className="absolute top-7 ">
                          <div className="w-[100px] h-[100px] border border-gray-300 ml-5 flex items-center justify-center rounded-lg">
                            <IoCloudUploadOutline size={30} />
                          </div>
                        </div>
                      )}
                      {dishDetail?.otherImages &&
                      dishDetail.otherImages.length > 0 ? (
                        <div
                          className={`flex flex-row gap-2 pb-1 ml-32 ${
                            dishDetail.otherImages.length > 2
                              ? "overflow-x-auto"
                              : ""
                          }`}
                        >
                          {dishDetail.otherImages.map((image, index) => (
                            <div key={index} className="relative ">
                              <div
                                className="flex justify-start items-center gap-2"
                                onMouseEnter={() =>
                                  handleMouseEnterOther(index)
                                }
                                onMouseLeave={() =>
                                  handleMouseLeaveOther(index)
                                }
                              >
                                <img
                                  className="rounded-lg object-contain w-[100px] h-[100px] ml-5 hover:opacity-45 border border-gray-300 p-2"
                                  src={image}
                                  alt={`otherImage-${index}`}
                                />

                                {hoveredIndexOther === index && (
                                  <div
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleRemoveOtherImage(index);
                                    }}
                                    className="absolute top-0 right-0 cursor-pointer text-primary font-bold bg-gray-300 rounded-full p-2"
                                  >
                                    <span
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleRemoveOtherImage(index);
                                      }}
                                    >
                                      <RxCross1 />
                                    </span>
                                  </div>
                                )}
                              </div>
                              {/* <div className="absolute top-0 left-32">
                              {index === dishDetail.otherImages.length - 1 && (
                                <div className="w-[100px] h-[100px] border border-gray-300 ml-5 flex items-center justify-center rounded-lg">
                                  <IoCloudUploadOutline size={30} />
                                </div>
                              )}
                            </div> */}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <span className="mt-3 leading-normal text-[#ABABAB] font-Glacial ">
                          <div className="flex flex-col  items-center justify-center mx-auto ml-32">
                            <IoCloudUploadOutline size={40} />
                            {t("addNewDish.uploadFoodImgs")}
                          </div>
                        </span>
                      )}

                      <input
                        name="otherImages"
                        type="file"
                        className="hidden border-0"
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                  {/* upload place image */}
                  <div className="border-[1px] border-gray-300 rounded-xl px-[16px] py-[24px]">
                    <p className="text-lg font-Glacial text-[#111] ">
                      {t("addNewDish.placeImgs")}
                      <span className="text-red-500">*</span>
                    </p>
                    <p className="flex">
                      <span
                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                      ></span>
                    </p>
                    <label className="relative mt-3 w-full h-40 rounded-xl flex flex-col items-start   cursor-pointer bg-white border mx-2">
                      <div className="absolute top-7">
                        {dishDetail?.placeImages?.length > 0 && (
                          <div className="w-[100px] h-[100px] border border-gray-300 ml-5 flex items-center justify-center rounded-lg">
                            <IoCloudUploadOutline size={30} />
                          </div>
                        )}
                      </div>
                      {dishDetail?.placeImages &&
                      dishDetail.placeImages.length > 0 ? (
                        <div
                          className={`flex flex-row gap-2 mt-7 pb-1 ml-32 ${
                            dishDetail.placeImages.length > 2
                              ? "overflow-x-auto"
                              : ""
                          }`}
                        >
                          {dishDetail.placeImages.map((image, index) => (
                            <div key={index} className="relative">
                              <div
                                className="flex justify-start items-center gap-2"
                                onMouseEnter={() =>
                                  handleMouseEnterPlace(index)
                                }
                                onMouseLeave={() =>
                                  handleMouseLeavePlace(index)
                                }
                              >
                                <img
                                  className="rounded-lg object-contain w-[100px] h-[100px] ml-5 hover:opacity-45 border border-gray-300 p-2"
                                  src={image}
                                  alt={`placeImage-${index}`}
                                  width={64}
                                  height={64}
                                />

                                {hoveredIndexPlace === index && (
                                  <div
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleRemovePlaceImage(index);
                                    }}
                                    className="absolute top-0 right-0 cursor-pointer text-primary font-bold bg-gray-200 rounded-full p-2 "
                                  >
                                    <span>
                                      <RxCross1 />
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <span className="mt-3 leading-normal text-[#ABABAB] font-Glacial ">
                          <div className="flex flex-col items-center justify-center mx-auto ml-32 mt-10">
                            <IoCloudUploadOutline size={40} />
                            {t("addNewDish.uploadPlaceImgs")}
                          </div>
                        </span>
                      )}

                      <input
                        name="placeImages"
                        type="file"
                        className="hidden border-0"
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                </div>

                <div className=" pt-4 container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
                  {/* food category */}
                  <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
                    <p className="text-lg font-Glacial text-[#111] pt-[16px]">
                      {t("addNewDish.foodCategory")}
                      <span className="text-red-500">*</span>
                    </p>
                    <p className="flex">
                      <span
                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                      ></span>
                    </p>
                    <div className="pt-[24px]">
                      <div>
                        <Dropdown
                          labelName=""
                          placeholderBlack
                          placeholder={
                            lang === "en" || lang === "en-US"
                              ? dishDetail?.translations?.en?.foodType?.foodType
                              : dishDetail?.foodType?.foodType
                          }
                          startIcon={<FoodCategory />}
                          options={
                            lang === "en" || lang === "en-US"
                              ? configs.categoryEn
                              : configs.category
                          }
                          onChange={(val) => {
                            // console.log("value: " + dishDetail?.foodType);
                            setDishDetail({
                              ...dishDetail,
                              foodType: val,
                            });
                          }}
                          size="small"
                          name="foodType"
                        />
                      </div>
                    </div>
                  </div>
                  {/* food type */}
                  <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
                    <p className="text-lg font-Glacial text-[#111] pt-[16px]">
                      {t("addNewDish.foodType")}
                      <span className="text-red-500">*</span>
                    </p>
                    <p className="flex">
                      <span
                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                      ></span>
                    </p>
                    <div className="pt-[24px]">
                      <div>
                        <Dropdown
                          name="foodClass"
                          labelName=""
                          placeholderBlack
                          placeholder={
                            lang === "en" || lang === "en-US"
                              ? dishDetail?.translations?.en?.foodClass
                              : dishDetail?.foodClass
                          }
                          startIcon={<FourSqure />}
                          options={
                            lang === "en" || lang === "en-US"
                              ? [
                                  { value: "Vegetariano", label: "Vegetarian" },
                                  {
                                    value: "No Vegetariano",
                                    label: "Non-Vegetarian",
                                  },
                                  { value: "Vegano", label: "Vegan" },
                                ]
                              : [
                                  { value: "Vegetariano", label: "Vegetarian" },
                                  {
                                    value: "No Vegetariano",
                                    label: "Non-Vegetarian",
                                  },
                                  { value: "Vegano", label: "Vegano" },
                                ]
                          }
                          onChange={(val) => {
                            setDishDetail({
                              ...dishDetail,
                              foodClass: val.value,
                              translations: {
                                ...dishDetail?.translations,
                                en: {
                                  ...dishDetail?.translations?.en,
                                  foodClass: val.label,
                                },
                              },
                            });
                          }}
                          size="small"
                        />
                      </div>
                    </div>
                  </div>
                  {/* time */}
                  <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
                    <p className="text-lg font-Glacial text-[#111] pt-[16px]">
                      {t("addNewDish.foodTime")}
                      <span className="text-red-500">*</span>
                    </p>
                    <p className="flex">
                      <span
                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                      ></span>
                    </p>
                    <div className="flex pt-[35px] font-Glacial pb-3">
                      <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-white border-y border-l rounded-e-0 border-gray-300 rounded-s-xl ">
                        <FourSqure />
                      </span>
                      <div className=" h-[50px]  focus:ring-primary focus:border-primary  border bg-white rounded-e-xl ">
                        <Select
                          // defaultValue={dishDetail?.mealTiming?.filter(
                          //   (timing) => timing
                          // )}
                          defaultValue={
                            lang === "en" || lang === "en-US"
                              ? mealTimingArrayEng
                              : mealTimingArray
                          }
                          className="xl:w-[420px] lg:w-[300px] h-[50px]  focus:ring-primary focus:border-primary text-gray-300 font-Glacial text-lg "
                          name="mealTiming"
                          labelName=""
                          // placeholder={dishDetail?.mealTiming?.map(
                          //   (timing) => timing.timingName
                          // )}
                          options={
                            lang === "en" || lang === "en-US"
                              ? configs.timeEn
                              : configs.time
                          }
                          onChange={(selectedOptions) => {
                            const selectedValues = selectedOptions?.map(
                              (option) => option
                            );
                            // console.log("Selected time values", selectedValues);
                            setDishDetail({
                              ...dishDetail,
                              mealTiming: selectedValues,
                            });
                          }}
                          components={{
                            DropdownIndicator: CustomDropdownIndicator,
                            IndicatorSeparator: () => null,
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#fff",
                              primary: "#fff",
                            },
                          })}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              border: 0,
                              boxShadow: "none",
                              color: "#fff",
                              borderRadius: "18px",
                            }),
                            multiValueLabel: (styles) => ({
                              ...styles,
                              backgroundColor: "#FF914D",
                              color: "#fff",
                              borderRadius: "5px 0 0 5px",
                            }),
                            multiValueRemove: (styles) => ({
                              ...styles,
                              color: "#fff",
                              backgroundColor: "#FF914D",
                              borderRadius: "0 5px 5px 0",
                              ":hover": {},
                            }),
                            placeholder: (styles) => ({
                              ...styles,
                              backgroundColor: "#FF914D",
                              color: "#fff",
                              width: "90px",
                              paddingLeft: "8px",
                              borderRadius: " 5px",
                              fontWeight: "lighter",
                              marginTop: "5px",
                              marginLeft: "2px",
                            }),
                            option: (styles) => ({
                              ...styles,
                              color: "#333",
                            }),
                          }}
                          isMulti
                        />
                      </div>
                    </div>

                    {/* <div className="pt-[24px]">
                    <div>
                      <Dropdown
                        name="mealTiming"
                        labelName=""
                        placeholderBlack
                        placeholder={
                          lang === "en" || lang === "en-US"
                            ? dishDetail?.translations?.en?.mealTiming[0]
                                ?.timingName
                            : dishDetail?.mealTiming[0]?.timingName
                        }
                        startIcon={<FourSqure />}
                        options={
                          lang === "en" || lang === "en-US"
                            ? configs.timeEn
                            : configs.time
                        }
                        onChange={(val) => {
                          console.log("val", val);
                          setDishDetail({
                            ...dishDetail,
                            // mealTiming: val.value,
                            mealTiming: [val],
                          });
                        }}
                        size="small"
                      />
                    </div>
                  </div> */}
                  </div>
                </div>

                <div className="pt-4 container mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">
                  {/* description */}
                  <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
                    <p className="text-lg font-Glacial text-[#111] pt-[16px]">
                      {t("addNewDish.dishDesc")}
                      <span className="text-red-500">*</span>
                    </p>
                    <p className="flex">
                      <span
                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                      ></span>
                    </p>
                    <div className="pt-[12px]">
                      <div className="relative flex pt-[21px] font-Glacial">
                        <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0 border-gray-300 rounded-s-xl ">
                          <FourSqure />
                        </span>

                        <input
                          name="dishDescription"
                          type="text"
                          placeholder="Enter Your Dish Description"
                          value={dishDetail?.dishDescription}
                          onChange={(e) =>
                            setDishDetail({
                              ...dishDetail,
                              dishDescription: e.target.value,
                            })
                          }
                          className="pl-16 rounded-xl bg-white border text-gray-900  block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5 transition duration-200 focus:ring-primary focus:border-primary "
                        />
                      </div>
                    </div>

                    <div className="pt-[10px]">
                      <span className="text-black font-Glacial text-lg">
                        {t("english")}
                      </span>
                    </div>

                    <div className="relative flex pt-[10px] font-Glacial">
                      <span className="absolute h-[50px] inline-flex items-center px-3 text-sm text-gray-900 bg-transparent border-r rounded-e-0 border-gray-300 rounded-s-xl ">
                        <FourSqure />
                      </span>

                      <input
                        name="enDishDescription"
                        type="text"
                        placeholder="Enter Your Dish Description"
                        //   onChange={handleInputChange}
                        onChange={(e) => {
                          setDishDetail({
                            ...dishDetail,
                            translations: {
                              ...dishDetail?.translations,
                              en: {
                                ...dishDetail?.translations?.en,
                                dishDescription: e.target.value,
                              },
                            },
                          });
                        }}
                        value={dishDetail?.translations?.en?.dishDescription}
                        className="pl-16 transition duration-200 focus:ring-primary focus:border-primary rounded-xl bg-white border text-gray-900  block flex-1 min-w-0 w-full text-lg border-gray-300 p-2.5  "
                      />
                    </div>
                  </div>
                  {/* cancellation policy */}
                  <div className="border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
                    <p className="text-lg font-Glacial text-[#111] pt-[16px]">
                      {t("addNewDish.cancellationPolicy")}
                      <span className="text-red-500">*</span>
                    </p>
                    <p className="flex">
                      <span
                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                      ></span>
                    </p>
                    <div className="pt-[24px]">
                      <div>
                        <Dropdown
                          name="cancellationPolicy"
                          labelName=""
                          placeholderBlack
                          placeholder={
                            lang === "en" || lang === "en-US"
                              ? dishDetail?.translations?.en
                                  ?.cancellationPolicyDetails?.policyDetails
                              : dishDetail?.cancellationPolicyDetails
                                  ?.policyDetails
                          }
                          startIcon={<Cancelation />}
                          options={
                            lang === "en" || lang === "en-US"
                              ? configs.cancelEn
                              : configs.cancel
                          }
                          onChange={(val) => {
                            // console.log("val", val);
                            setDishDetail({
                              ...dishDetail,
                              cancellationPolicy: val.value,
                            });
                          }}
                          size="small"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-4 container mx-auto grid grid-cols-1 gap-4">
                  {/* accessibility */}
                  <div className=" border-[1px] border-gray-300 rounded-2xl px-[16px] py-[24px]">
                    <div className="flex justify-start items-center gap-4">
                      <div>
                        <p className="text-lg font-Glacial text-[#111] ">
                          {t("addNewDish.accesibility")}
                          <span className="text-red-500">*</span>
                        </p>
                        <p className="flex">
                          <span
                            className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                          ></span>
                        </p>
                      </div>
                    </div>
                    <div className="text-[#ABABAB] pt-[21px] font-Glacial">
                      <span>{t("addNewDish.mentionAcc")}</span>
                    </div>
                    <div className="container font-Glacial mx-auto mt-3">
                      <div className="mb-4">
                        <input
                          type="text"
                          className="w-1/2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-primary focus:ring-2 focus:ring-primary transition delay-100"
                          placeholder={t("addNewDish.egStair")}
                          value={inputText}
                          onChange={handleInputChangeAcce}
                          onKeyPress={handleInputKeyPress}
                        />
                      </div>

                      <div className="flex flex-wrap">
                        {dishDetail?.placeAccessibility?.map((label, index) => (
                          <div
                            key={index}
                            className="relative bg-primary text-white font-Glacial rounded-md pl-4 pr-10 py-1 mr-2 mb-2 capitalize"
                          >
                            {label}
                            <button
                              className="absolute top-1 right-2 mt-1 mr-1 focus:outline-none"
                              onClick={() => removeLabel(index)}
                            >
                              <RxCross1 />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" sm:container mx-auto mb-[70px] pt-[32px] flex justify-end">
                  {btnLoading ? (
                    <div className="px-[26px] py-[10px] text-lg rounded-full border-primary bg-white text-[#FFF]  border-[3px] w-[130px] h-[50px] ">
                      <Loader size={25} />
                    </div>
                  ) : (
                    <DBtn
                      variant="contain"
                      size="medium"
                      onClick={handleUpdate}
                      startIcon=""
                    >
                      {t("addNewDish.update")}
                    </DBtn>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DishFormEdit;
