import React, { useEffect, useState } from "react";
import PriceRangeSelector from "./PriceRangeSelector";
import Dropdown from "./Dropdown";
import FoodCategory from "../svg/FoodCategory";
import FourSqure from "../svg/FourSqure";
import DBtn from "./DBtn";
import SuperHostRadioBtn from "../components/SuperHostRadioBtn";
import EmptyStar from "../svg/EmptyStar";
import { getFoodTypes, getMealTimes } from "../services/commonService";
import { useTranslation } from "react-i18next";
import SideNavSelect from "./SideNavSelect";
import { useSelector } from "react-redux";

const Filter = ({ filters, setFilters, setFlag, onClose }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");

  const { user } = useSelector((state) => state.auth);
  const [foodTypeList, setFoodTypeList] = useState([]);
  const [foodTypeListEn, setFoodTypeListEn] = useState([]);
  const [mealTimeList, setMealTimeList] = useState([]);
  const [mealTimeListEn, setMealTimeListEn] = useState([]);
  const getFoodTypeData = async () => {
    try {
      const resp = await getFoodTypes();
      if (resp?.success) {
        const foodTypesEn = resp?.data?.map((type) => {
          return {
            value: type?._id,
            label: type?.translations?.en?.foodType,
          };
        });
        const foodTypes = resp?.data?.map((type) => {
          return {
            value: type?._id,
            label: type?.foodType,
          };
        });
        setFoodTypeListEn(foodTypesEn);
        setFoodTypeList(foodTypes);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
    }
  };

  useEffect(() => {
    getFoodTypeData();
  }, []);

  const getMealTimeData = async () => {
    try {
      const resp = await getMealTimes();
      if (resp?.success) {
        const mealTimesEn = resp?.data?.map((meal) => {
          return {
            value: meal?._id,
            label: meal?.translations?.en?.timingName,
          };
        });
        const mealTimes = resp?.data?.map((meal) => {
          return {
            value: meal?._id,
            label: meal?.timingName,
          };
        });
        setMealTimeListEn(mealTimesEn);
        setMealTimeList(mealTimes);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
    }
  };

  useEffect(() => {
    getMealTimeData();
  }, []);
  // console.log("mealTimeList", mealTimeList);
  // console.log("mealTimeListEn", mealTimeListEn);

  const handleVerifyClick = async (e) => {
    e.preventDefault();
    onClose();
    setFlag(true);
  };

  const updateFoodType = (value, label) => {
    // Check if the value is already in the array
    if (filters.foodTypeFilter.includes(value)) {
      // If it's in the array, remove it
      setFilters((prevFilters) => ({
        ...prevFilters,
        foodTypeFilter: prevFilters.foodTypeFilter.filter(
          (type) => type !== value
        ),
      }));
      // console.log("filters?.foodTypeFilter,", filters?.foodTypeFilter);
    } else {
      // If it's not in the array, add it
      setFilters((prevFilters) => ({
        ...prevFilters,
        foodTypeFilter: [
          // ...prevFilters.foodTypeFilter,
          { value: value, label: label },
        ],
      }));
    }
  };

  const updateMealTime = (value, label) => {
    // Check if the value is already in the array
    if (filters.mealTimingsFilter.includes(value)) {
      // If it's in the array, remove it
      setFilters((prevFilters) => ({
        ...prevFilters,
        mealTimingsFilter: prevFilters.mealTimingsFilter.filter(
          (type) => type !== value
        ),
      }));
    } else {
      // If it's not in the array, add it
      setFilters((prevFilters) => ({
        ...prevFilters,
        mealTimingsFilter: [
          // ...prevFilters.mealTimingsFilter,
          { value: value, label: label },
        ],
      }));
    }
  };

  const updateFoodClass = (value, label) => {
    // Check if the value is already in the array
    if (filters.foodClass.includes(value)) {
      // If it's in the array, remove it
      setFilters((prevFilters) => ({
        ...prevFilters,
        foodClass: prevFilters.foodClass.filter((type) => type !== value),
      }));
    } else {
      // If it's not in the array, add it
      setFilters((prevFilters) => ({
        ...prevFilters,
        foodClass: [
          // ...prevFilters.foodClass,
          { value: value, label: label },
        ],
      }));
    }
  };

  const updateRating = (value, label) => {
    // Check if the value is already in the array
    if (filters.ratingsFilter.includes(value)) {
      // If it's in the array, remove it
      setFilters((prevFilters) => ({
        ...prevFilters,
        ratingsFilter: prevFilters.ratingsFilter.filter(
          (type) => type !== value
        ),
      }));
    } else {
      // If it's not in the array, add it
      setFilters((prevFilters) => ({
        ...prevFilters,
        ratingsFilter: [
          // ...prevFilters.ratingsFilter,
          { value: value, label: label },
        ],
      }));
    }
  };

  return (
    <div className="mx-auto">
      <div className="container mx-auto mt-2 z-50">
        {/* food type */}
        <div className="mt-2 z-50 ">
          <SideNavSelect
            onLocationChange={(val) => {
              updateFoodType(val?.value, val?.label);
            }}
            options={
              lang === "en" || lang === "en-US" ? foodTypeListEn : foodTypeList
            }
            icon={<FoodCategory />}
            value={filters?.foodTypeFilter}
            placeholder={t("homePage.selectPlate")}
          />
          {/* <Dropdown
            placeholder={t("homePage.selectPlate")}
            startIcon={<FoodCategory />}
            options={foodTypeList}
            onChange={(val) => {
              updateFoodType(val?.value);
            }}
            size="small"
          /> */}
        </div>

        {/* meal time */}
        {user?.role !== "host" && (
          <div className="mt-2">
            {/* <Dropdown
            placeholder={t("homePage.selectMealTime")}
            startIcon={<FourSqure />}
            options={mealTimeList}
            onChange={(val) => {
              updateMealTime(val?.value);
            }}
            size="small"
          /> */}
            <SideNavSelect
              onLocationChange={(val) => {
                updateMealTime(val?.value, val?.label);
              }}
              options={
                lang === "en" || lang === "en-US"
                  ? mealTimeListEn
                  : mealTimeList
              }
              icon={<FourSqure />}
              value={filters?.mealTimingsFilter}
              placeholder={t("homePage.selectMealTime")}
            />
          </div>
        )}
        {/* food class */}
        {user?.role !== "host" && (
          <div className="mt-2">
            <SideNavSelect
              onLocationChange={(val) => {
                updateFoodClass(val?.value, val?.label);
              }}
              options={
                lang === "en" || lang === "en-US"
                  ? [
                      { value: "vegetariano", label: "Veg" },
                      { value: "No vegetariano", label: "Nonveg" },
                      { value: "vegano", label: "Vegan" },
                    ]
                  : [
                      { value: "vegetariano", label: "Vegetariano" },
                      { value: "No vegetariano", label: "No vegetariano" },
                      { value: "vegano", label: "Vegano" },
                    ]
              }
              icon={<FourSqure />}
              value={filters?.foodClass}
              placeholder={t("homePage.selectFoodClass")}
            />
            {/* <Dropdown
            placeholder={t("homePage.selectFoodClass")}
            startIcon={<FourSqure />}
            options={[
              { value: "vegetariano", label: "Veg" },
              { value: "No vegetariano", label: "Nonveg" },
              { value: "vegano", label: "Vegan" },
            ]}
            onChange={(val) => {
              // console.log("Selected class:", val);
              updateFoodClass(val?.value);
            }}
            size="small"
          /> */}
          </div>
        )}
        {/* rating */}
        {user?.role !== "host" && (
          <div className="font-Glacial mt-2">
            <SideNavSelect
              onLocationChange={(val) => {
                updateRating(val?.value, val?.label);
              }}
              options={
                lang === "en" || lang === "en-US"
                  ? [
                      { value: 5, label: "⭐️ ⭐️ ⭐️ ⭐️ ⭐️" },
                      { value: 4, label: "⭐️ ⭐️ ⭐️ ⭐️ Above" },
                      { value: 3, label: "⭐️ ⭐️ ⭐️ Above" },
                      { value: 2, label: "⭐️ ⭐️ Above" },
                      { value: 1, label: "⭐️ Above" },
                    ]
                  : [
                      { value: 5, label: "⭐️ ⭐️ ⭐️ ⭐️ ⭐️" },
                      { value: 4, label: "⭐️ ⭐️ ⭐️ ⭐️ Arriba" },
                      { value: 3, label: "⭐️ ⭐️ ⭐️ Arriba" },
                      { value: 2, label: "⭐️ ⭐️ Arriba" },
                      { value: 1, label: "⭐️ Arriba" },
                    ]
              }
              icon={<EmptyStar />}
              value={filters?.ratingsFilter}
              placeholder={t("homePage.ratings")}
            />
            {/* <Dropdown
            placeholder={t("homePage.ratings")}
            startIcon={<EmptyStar />}
            options={[
              { value: 5, label: "⭐️ ⭐️ ⭐️ ⭐️ ⭐️" },
              { value: 4, label: "⭐️ ⭐️ ⭐️ ⭐️ Above" },
              { value: 3, label: "⭐️ ⭐️ ⭐️ Above" },
              { value: 2, label: "⭐️ ⭐️ Above" },
              { value: 1, label: "⭐️ Above" },
            ]}
            onChange={(val) => {
              console.log("Selected rating:", val);
              setFilters({
                ...filters,
                ratingsFilter: val?.value,
              });
            }}
            size="small"
          /> */}
          </div>
        )}
        {/* price range */}
        {/* <div className="flex pt-[5px]">
          <PriceRangeSelector
            value={[filters?.lowerPriceRange, filters?.higherPriceRange]}
            onChange={(val) => {
              setFilters({
                ...filters,
                lowerPriceRange: val[0],
                higherPriceRange: val[1],
              });
            }}
          />
        </div> */}
        {/* super host */}
        {user?.role !== "host" && (
          <div className="">
            <SuperHostRadioBtn
              checked={filters?.superHostFilter}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  superHostFilter: e.target.checked,
                });
              }}
            />
          </div>
        )}

        <div className="flex justify-center items-center mt-2">
          <DBtn
            variant="contain"
            size="medium"
            onClick={handleVerifyClick}
            startIcon=""
          >
            {t("homePage.apply")}
          </DBtn>
        </div>
      </div>
    </div>
  );
};

export default Filter;
